import React from 'react'
import Axios from "axios";
import {API} from "../../../api/APIClient";
import {Link} from "react-router-dom";
import {SECTIONS} from "../../../resources/strings";



class VenueDetails extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            details: undefined
        };
        //this.handleChange = this.handleChange.bind(this);
    }


    componentDidMount() {

        this.getDetails()

    }


    getDetails() {
        const formData = new FormData();
        formData.append('venueID', this.props.venue.id);

        Axios
            .post(API.locations.getDetails, formData)
            .then((res) => {

                if (res.data.success) {

                    if (res.data.data === false) {

                    } else {
                        this.setState({
                            details: res.data.data
                        })
                    }

                }
            })
    }

    render() {

        if (this.state.details === undefined){
            return (<div>Loading details...</div>)

        } else {

            const addr_array = this.state.details.address.split(',');


            return (<div className="cell small-12 venue_deets details-wrap small-pad">

                    <div className="deets_sect">
                        <div className="cell small-12 small-pad">
                            <div className="title">Main Venue Details</div>
                        </div>

                        <div className="cell small-4 small-pad">
                            <div className="deet">
                                <span>Venue ID</span>
                                <div className="field">{this.state.details.id}</div>
                            </div>
                        </div>
                        <div className="cell small-8 small-pad">
                            <div className="deet">
                                <span>Venue Name</span>
                                <div className="field">{this.state.details.locationname}</div>
                            </div>
                        </div>

                        <div className="cell small-6 small-pad">
                            <div className="deet">
                                <span>Venue Address</span>
                                <div className="field">{this.state.details.address}</div>
                                <div className="field">{this.state.details.address2}</div>
                                <div className="field">{this.state.details.town}</div>
                                <div className="field">{this.state.details.county}</div>
                                <div className="field">{this.state.details.postcode}</div>
                            </div>
                        </div>
                        <div className="cell small-6 small-pad">
                            <div className="deet">
                                <span>Contact Name</span>
                                <div className="field">{this.state.details.contact}</div>
                            </div>
                            <div className="deet">
                                <span>Email</span>
                                <div className="field">{this.state.details.email}</div>
                            </div>
                            <div className="deet">
                                <span>Contact Phone</span>
                                <div className="field">{this.state.details.phonenumber}</div>
                            </div>
                        </div>

                        {(this.props.user.admin === "1" || this.props.user.technician === "1")  &&
                        <div className="cell small-12 small-pad">
                            <div className="deet notes-deet">
                                <span>Venue Notes</span>
                                <div className="field">{this.state.details.notes}</div>
                            </div>
                        </div>
                        }


                        <div className="clearfix"></div>

                    </div>


                    <div className="deets_sect">
                        <div className="cell small-12 small-pad">
                            <div className="title">Client Details</div>
                        </div>

                        <div className="cell small-6 small-pad">
                            <div className="deet">
                                <span>Client Name</span>
                                <div className="field">{this.state.details.clientname}</div>
                            </div>
                        </div>
                        <div className="cell small-6 small-pad">
                            <div className="deet">
                                <span>Lead Brewer Name</span>
                                <div className="field">{this.state.details.lead_brewer}</div>
                            </div>
                        </div>

                        <div className="clearfix"></div>

                    </div>

                    <div className="deets_sect">
                        <div className="cell small-12 small-pad">
                            <div className="title">Client Tech Service Provider Details</div>
                        </div>

                        <div className="cell small-12 small-pad">
                            <div className="deet">
                                <span>TSP Name</span>
                                <div className="field">{this.state.details.tsp_name}</div>
                            </div>
                            <div className="deet">
                                <span>TSP Phone Number</span>
                                <div className="field">{this.state.details.tsp_phone}</div>
                            </div>
                            <div className="deet">
                                <span>TSP Email</span>
                                <div className="field">{this.state.details.tsp_email}</div>
                            </div>
                        </div>

                        <div className="clearfix"></div>

                    </div>

                    {(this.props.user.admin === "1") &&
                        <div className="cell small-12">
                            <Link to={`/` + SECTIONS.ADMIN + "/ev/" + this.props.client.id + "_" + this.props.venue.id }><div className="av-button">View &amp; Edit client &amp; venue details</div></Link>
                        </div>
                    }

                </div>
                )


        }
    }


}

export default VenueDetails;
