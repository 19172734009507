import React from 'react'



class  Footer extends React.Component {

    constructor(props) {
        super(props);

        this.state = {};

    }


    render() {
        return (
            <div className="footer">&copy; Avani Solutions Ltd</div>
        )
    }
}
export default Footer;
