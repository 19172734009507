import React from "react";
import {CLIENT_TABS} from "../../../resources/strings";

class FaultsHeader extends React.Component {

    constructor(props) {
        super(props);

        this.state = {

        };
        //this.handleChange = this.handleChange.bind(this);
    }


    componentDidMount() {

    }

    render() {
        return <div className="clientsheader">
            <div className="title">Fault - {this.props.faultDetails && this.props.faultDetails.locationname} - {this.props.faultDetails && this.props.faultDetails.date}</div>

            {this.props.forceClientSelection ?
                <div className="bread" style={{paddingBottom:"12px"}}><span className="crumb">Overall summary</span> > <span
                    className="crumb" onClick={() => this.props.backClickHandler()}>{this.props.client.name}</span>
                    <span className="crumb" onClick={() => this.props.deSelectFault()}> > {this.props.venue.name}</span>  > Fault
                </div>
                :
                <div className="bread" style={{paddingBottom:"12px"}}><span className="crumb" onClick={() => this.props.backToTopClickHandler()}>Overall summary</span> > <span
                    className="crumb" onClick={() => this.props.backClickHandler()}>{this.props.client.name}</span>
                    <span className="crumb" onClick={() => this.props.deSelectFault()}> > {this.props.venue.name}</span>  > Fault
                </div>
            }

        </div>

    }
}

export default FaultsHeader;
