import React from 'react'
import ForgotPassword from "./ForgotPassword"
import Axios from 'axios'
import { API } from '../../api/APIClient'

class LoginScreen extends React.Component {

    constructor(props) {
        super(props);

        this.SCREENS = {
            LOGIN: "login",
            FORGOT_PASSWORD: "forgot_pass"
        };
        var currentScreen = this.SCREENS.LOGIN;

        this.state = {
            currentScreen: currentScreen,
            navStack: [],
            submitting: false,
            email: "",
            password: ""
        };
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(event) {

        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });


    }
    loginClicked()
    {

        if (this.state.email.length > 0 && this.state.password.length > 0)
        {
            this.setState({
                submitting:true,
                submitMessage:"Logging in..."
            });
            const formData = new FormData();
            formData.append('email', this.state.email);
            formData.append('password', this.state.password);

            Axios
                .post(API.auth.login, formData)
                .then((res) => {

                    this.setState({
                        submitting:false,
                        submitMessage:""
                    });
                    if (res.data.success) {

                        var user = res.data.data.user;
                        user.token = res.data.data.token;

                        this.props.loginHandler(user);

                    }

                }).catch(error => {

                this.setState({
                    submitting:false,
                    submitMessage:""
                });

                console.log(error)
                if (error.response)
                {
                    if (error.response.data.error) {

                        alert(error.response.data.error)
                    } else{
                        alert("Could not log in.  Please check connection and try again.")

                    }
                }else{
                    alert(error)

                }

            });

        } else {
            alert("Please enter your email and password.")
        }



    }

    forgotPassClicked() {
        this.handleItemClick(this.SCREENS.FORGOT_PASSWORD);
    }


    handleItemClick (newScreen)
    {
        const oldScreen = this.state.currentScreen;
        const navStack = this.state.navStack.concat([oldScreen])
        this.setState({
            currentScreen: newScreen,
            navStack: navStack
        });
    }

    handleBackClick()
    {
        const navStack = this.state.navStack.slice();

        if (navStack.length === 0)
        {
            window.history.back();
        } else {
            const backScreen = navStack[navStack.length - 1];

            this.setState({
                currentScreen: backScreen,
                navStack: navStack.slice(0,navStack.length - 1)
            });
        }

    }

    enterPressed(event) {
        var code = event.keyCode || event.which;
        if(code === 13) { //13 is the enter keycode
            this.loginClicked()
        }
    }

    render() {
        switch (this.state.currentScreen) {

            case this.SCREENS.LOGIN :

                return(<div className="loginMain">

                    <div className="big-logo"></div>

                    <div className="login-box">

                        <h2>Login</h2>

                        <div className="main-wrap">

                            <div className="cell small-12">
                                <label>
                                    <span className="login-label">Email</span>
                                    <input className="login-field" type="text" name="email" placeholder="Email" value={this.state.email} onChange={this.handleChange}/>
                                </label>
                            </div>

                            <div className="cell small-12">
                                <label>
                                    <span className="login-label">Password</span>
                                    <input className="login-field" type="password" name="password" placeholder="Password" value={this.state.password} onChange={this.handleChange}     onKeyPress={this.enterPressed.bind(this)}
                                    />
                                </label>
                            </div>
                            <div className="clearfix"></div>

                            <div><div onClick={() => this.loginClicked()} className="av-button">Login</div></div>

                            <div> <div onClick={() => this.forgotPassClicked()} className="small-link">Forgotten Password</div></div>
                        </div>
                    </div>
                    <div className="clearfix"></div>
                </div>);
            case this.SCREENS.FORGOT_PASSWORD :
                return(<ForgotPassword backHandler={() => this.handleBackClick()}  />);


            default :
                return(<div>unknown screen</div>);
        }
    }



}

export default LoginScreen;
