import React from 'react';
import DashBoard from "./components/DashBoard"
import './App.css';
import './assets/css/main.css';
import ReactGA from 'react-ga';

function App() {

    return (
      <div className="App">
        <DashBoard />
      </div>
  );
}

export default App;
