import React from "react";
import Axios from "axios";
import {API} from "../../../api/APIClient";

class Temperatures extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            temperatures : props.temperatures
        }
        this.handleChange = this.handleChange.bind(this);

    }



    componentDidMount() {

    }

    handleChange(event) {

        const target = event.target
        const value = target.value
        const namearr = target.name.split("_");
        if (namearr.length !== 2) {
            return;
        }
        const name = namearr[0];
        const index = namearr[1];

        var temps = this.state.temperatures
        var row = temps[index]
        row[name] = value
        temps[index] = row
        this.setState({
            temperatures: temps,
            saved: false
        });
    }


    addItem() {
        const formData = new FormData();
        formData.append('reportID', this.props.visitID);

        Axios
            .post(API.visits.addTemperature, formData)
            .then((res) => {

                if (res.data.success) {

                    this.setState({
                        temperatures: res.data.temperatures
                    })


                } else {
                    var msg = "Error adding reading, please check your connection and try again."
                    alert(msg)
                    this.setState({
                        saving: false
                    })

                }
            }).catch(error => {

            var msg = "Error adding reading. "
            this.setState({
                saving: false
            })
            if (error.response) {
                if (error.response.data.error) {
                    msg = msg + error.response.data.error
                }
                alert(msg)
            } else {
                alert(error)

            }
        })
    }



    deleteItem(rowIdx) {

        if (window.confirm("Are you sure you want to delete this reading? This can not be undone!")) {
            var temperatures = this.state.temperatures
            var row = temperatures[rowIdx]

            const formData = new FormData();
            formData.append('reportID', this.props.visitID);
            formData.append('temperatureID', row.id);

            Axios
                .post(API.visits.deleteTemperature, formData)
                .then((res) => {

                    if (res.data.success) {

                        this.setState({
                            temperatures: res.data.temperatures
                        })


                    } else {
                        var msg = "Error deleting temperature, please check your connection and try again."
                        alert(msg)
                        this.setState({
                            saving: false
                        })

                    }
                }).catch(error => {

                var msg = "Error deleting temperature. "
                this.setState({
                    saving: false
                })
                if (error.response) {
                    if (error.response.data.error) {
                        msg = msg + error.response.data.error
                    }
                    alert(msg)
                } else {
                    alert(error)

                }
            })
        }

    }


    render() {

        return (<div style={{textAlign: "center"}}>
            <div style={{float: "right"}} className="av-button" onClick={() => this.addItem()}> + Add Reading</div>

            <table cellPadding={6} style={{width: "100%"}}><tr bgcolor="#23A6A6" style={{width: "100%",color:"#ffffff", fontWeight : "bold"}}>
                <td>Remote Name</td><td>Type</td><td>Bar</td><td>Reading</td><td></td></tr>

            {this.state.temperatures && this.state.temperatures.map((row, c) => {

                return <tr bgcolor="#dedede">
                    <td>{row.remotename}</td>
                    <td><select className="edit-field" value={row.type} name={"type_"+c} onChange={this.handleChange}>
                        <option value="Remote">Remote</option>
                        <option value="Cellar">Cellar</option>
                    </select></td>
                    <td><select className="edit-field" value={row.barID} name={"barID_"+c} onChange={this.handleChange}>
                        <option value=""></option>
                        {this.props.bars.map((row, c) => {
                            return <option value={row.id}>{row.barname}</option>
                        })}
                    </select></td>
                    <td><input className="edit-field" type="text" name={"reading_"+c} placeholder="Reading" value={row.reading} onChange={this.handleChange}/>
                    </td>
                    <td width={35}>
                        <div  style={{width: "35px", height: "35px"}} className="delete" onClick={() => this.deleteItem(c)}></div>

                    </td>
                </tr>

            })}

            </table>

            {this.state.saving
                ?
                <div className="av-button">Saving...</div>
                :
                <div className="av-button" onClick={() => this.setState({saving: true,saved: false}, this.saveChanges())}>Save Temperatures</div>
            }
            {this.state.saved && <div>Changes to temperatures saved successfully</div>}

        </div>);
    }

    saveChanges() {
        if (this.state.temperatures) {
            const formData = new FormData();
            formData.append('reportID', this.props.visitID);
            formData.append('temperatures', JSON.stringify(this.state.temperatures));

            Axios
                .post(API.visits.editTemperatures, formData)
                .then((res) => {

                    if (res.data.success) {

                        this.setState({
                            saving: false,
                            saved: true
                        })


                    } else {
                        var msg = "Error saving temperatures, please check the details entered and try again."
                        alert(msg)
                        this.setState({
                            saving: false
                        })

                    }
                }).catch(error => {

                var msg = "Error saving temperatures. "
                this.setState({
                    saving: false
                })
                if (error.response) {
                    if (error.response.data.error) {
                        msg = msg + error.response.data.error
                    }
                    alert(msg)
                } else {
                    alert(error)

                }
            })
        }

    }


}
export default Temperatures
