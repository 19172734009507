import React from "react";
import Axios from "axios";
import {API} from "../../api/APIClient";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import Paper from "@material-ui/core/Paper/Paper";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TablePagination from "@material-ui/core/TablePagination";
import {SECTIONS} from "../../resources/strings";


class Feedback extends React.Component {


    constructor(props) {
        super(props);
        this.state = {tableData: undefined,
            page: 0,readpage:0,
            rowsPerPage: 25
        };

    }


    componentDidMount()
    {
        this.getFeedback()
        this.getReadFeedback()
    }



    getFeedback() {
        const formData = new FormData();

        Axios
            .post(API.feedback.getFeedback, formData)
            .then((res) => {

                if (res.data.success) {

                    if (res.data.data === false)
                    {
                        this.setState({
                            tableData: []
                        })
                    } else {
                        this.setState({
                            tableData: res.data.data.map((feedback, c) => {



                                return <TableRow key={c}>
                                    <TableCell component="th" scope="row">{feedback.sent}</TableCell>
                                    <TableCell component="th" scope="row">{feedback.fromName}</TableCell>
                                    <TableCell component="th" scope="row">{feedback.messageType}</TableCell>
                                    <TableCell component="th" scope="row">{feedback.message}</TableCell>
                                    <TableCell component="th" scope="row"><div className="av-button" style={{padding: "2px 30px"}} onClick={() => this.viewMsg(feedback.id, true)}>View</div></TableCell>

                                </TableRow>


                            })

                        })

                    }
                }
            })
    }


    viewMsg(msgID, markAsRead)
    {
        if (markAsRead) {
            this.markAsRead(msgID)
        }
        this.props.itemClickHandler(msgID)
    }

    markAsRead(msg)
    {
        const formData = new FormData();
        formData.append('msgID', msg);

        Axios
            .post(API.feedback.markAsRead, formData)
            .then((res) => {

            })
    }

    getReadFeedback() {
        const formData = new FormData();

        Axios
            .post(API.feedback.getReadFeedback, formData)
            .then((res) => {

                if (res.data.success) {

                    if (res.data.data === false)
                    {
                        this.setState({
                            tableData: []
                        })
                    } else {
                        this.setState({
                            readTableData: res.data.data.map((feedback, c) => {



                                return <TableRow key={c}>
                                    <TableCell component="th" scope="row">{feedback.sent}</TableCell>
                                    <TableCell component="th" scope="row">{feedback.fromName}</TableCell>
                                    <TableCell component="th" scope="row">{feedback.messageType}</TableCell>
                                    <TableCell component="th" scope="row">{feedback.message}</TableCell>
                                    <TableCell component="th" scope="row"><div className="av-button" style={{padding: "2px 30px"}} onClick={() => this.viewMsg(feedback.id, false)}>View</div></TableCell>

                                </TableRow>


                            })

                        })

                    }
                }
            })
    }





    render() {

        return (<div>
            <a href="mailto:" style={{display:"none"}} className="Commented out may need in future"><div style={{float:"right"}} className="av-button">Send Client Feedback</div></a>

            <div className="cell small-12 admin-wrap">
                <div className="admin-box">
                    
                        <div className="title">New Messages</div>
                    
                

                <TableContainer component={Paper}>
                    <Table  size="small" aria-label="clean results">
                        <TableHead>
                            <TableRow>
                                <TableCell>Date</TableCell>
                                <TableCell>From</TableCell>
                                <TableCell>Feedback Type</TableCell>
                                <TableCell>Message</TableCell>
                                <TableCell> </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.state.tableData === undefined ? <TableRow><TableCell>Loading...</TableCell></TableRow> : this.state.tableData.slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage)}
                        </TableBody>
                    </Table>

                </TableContainer>
                {this.state.tableData &&
                <TablePagination
                    rowsPerPageOptions={[10, 25, 100]}
                    component={Paper}
                    count={this.state.tableData === undefined ? 0 : this.state.tableData.length}
                    rowsPerPage={this.state.rowsPerPage}
                    page={this.state.page}
                    onChangePage={(event, newPage) => {

                        this.setState({page: newPage})
                    }}
                    onChangeRowsPerPage={(event) => {
                        this.setState({page: 0, rowsPerPage : parseInt(event.target.value, 10)})
                    }}
                />}
                </div>
            </div>


            <div className="cell small-12 admin-wrap">
                <div className="admin-box">
                <div className="title">Previous Messages</div>

                <TableContainer component={Paper}>
                    <Table  size="small" aria-label="clean results">
                        <TableHead>
                            <TableRow>
                                <TableCell>Date</TableCell>
                                <TableCell>From</TableCell>
                                <TableCell>Feedback Type</TableCell>
                                <TableCell>Message</TableCell>
                                <TableCell> </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.state.readTableData === undefined ? <TableRow><TableCell>Loading...</TableCell></TableRow> : this.state.readTableData.slice(this.state.page * this.state.rowsPerPage, this.state.readpage * this.state.rowsPerPage + this.state.rowsPerPage)}
                        </TableBody>
                    </Table>

                </TableContainer>
                {this.state.tableData &&
                <TablePagination
                    rowsPerPageOptions={[10, 25, 100]}
                    component={Paper}
                    count={this.state.readTableData === undefined ? 0 : this.state.readTableData.length}
                    rowsPerPage={this.state.rowsPerPage}
                    page={this.state.readpage}
                    onChangePage={(event, newPage) => {

                        this.setState({readpage: newPage})
                    }}
                    onChangeRowsPerPage={(event) => {
                        this.setState({readpage: 0, rowsPerPage : parseInt(event.target.value, 10)})
                    }}
                />}
                </div>
            </div>

                </div>)
    }

}
export default Feedback;