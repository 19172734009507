import React from "react";
import {ADMIN_SECTIONS, WARRANTY_SECTIONS} from "../../../resources/strings";
import TableContainer from "@material-ui/core/TableContainer";
import Paper from "@material-ui/core/Paper/Paper";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell/TableCell";
import TablePagination from "@material-ui/core/TablePagination";
import Axios from "axios";
import {API} from "../../../api/APIClient";


class Parts extends React.Component {


    constructor(props) {
        super(props);

        this.state = {
            page: 0,
            rowsPerPage: 25
        };
        this.handleChange = this.handleChange.bind(this);

    }


    handleChange(event) {

        const target = event.target;
        const name = target.name;

        this.setState({
            [name]: target.value
        }, ()=>this.updateMatching());



    }


    componentDidMount() {
        setTimeout(
            () => this.getParts(),
            100
        );
    }



    getParts() {
        const formData = new FormData();

        Axios
            .post(API.warranty.getParts, formData)
            .then((res) => {

                if (res.data.success) {

                    this.setState({
                        parts: res.data.parts,
                    }, () => this.updateMatching())


                }
            })
    }

    hidePartModal() {
        this.setState({selectedPart: undefined, editingPart: false})
    }

    handlePartDetailsClick(indx) {
        this.setState({selectedPart: indx})
    }

    editPartClicked()
    {
        this.setState({editingPart: true,
            edit_partName: this.state.parts[this.state.selectedPart].partName,
            edit_minStock: this.state.parts[this.state.selectedPart].minStock,
            edit_partCode: this.state.parts[this.state.selectedPart].partCode
        })


    }

    newPartClicked()
    {
        this.setState({selectedPart: -1,
            editingPart: true,
            edit_partName: "",
            edit_partCode: "",
            edit_minStock: "0"
        })
    }

    deletePart(partID) {
        if (window.confirm("Are you sure? This can not be undone!")) {
            const formData = new FormData();

            formData.append('partID', partID);


            Axios
                .post(API.warranty.deletePart, formData)
                .then((res) => {

                    if (res.data.success) {

                        this.setState({selectedPart: undefined, editingPart: false, parts : undefined},
                            () => this.getParts())

                    } else {
                        var msg = "Error deleting part, please try again."
                        alert(msg)

                    }
                }).catch(error => {

                var msg = "Error in part deletion."

                if (error.response) {
                    if (error.response.data.error) {
                        msg = msg + error.response.data.error
                    }

                    alert(msg)
                } else {
                    alert(error)

                }
            })
        }
    }


    updateMatching() {
        var matchingParts = []
        for (var c = 0; c < this.state.parts.length; c++) {
            if (! this.state.search || (this.state.parts[c].partName.toLowerCase().includes(this.state.search.toLowerCase())  || this.state.parts[c].partCode.toLowerCase().includes(this.state.search.toLowerCase())   )   ){
                matchingParts.push(this.state.parts[c])
            }
        }

        this.setState({
            tableData: matchingParts.map((part, c) => {

                return <TableRow key={c}>
                    <TableCell component="th" scope="row">{part.partCode}</TableCell>
                    <TableCell component="th" scope="row">{part.partName}</TableCell>
                    <TableCell component="th" scope="row">{part.minStock}</TableCell>
                    <TableCell>
                        <div onClick={() => this.handlePartDetailsClick(c)} className="av-button" style={{padding: "2px 30px"}}>Edit</div>
                        <div onClick={() => this.deletePart(part.id)} className="av-button" style={{padding: "2px 30px"}}>Delete</div>

                    </TableCell>
                </TableRow>

            })
        })
    }



    savePartClicked() {
        if (this.state.edit_partName) {
            const formData = new FormData();
            if (this.state.selectedPart === -1) {
                formData.append('partID', -1);

            } else {
                formData.append('partID', this.state.parts[this.state.selectedPart].id);

            }
            formData.append('partName', this.state.edit_partName);
            formData.append('partCode', this.state.edit_partCode);
            formData.append('minStock', this.state.edit_minStock);


            Axios
                .post(API.warranty.editPart, formData)
                .then((res) => {

                    if (res.data.success) {

                        alert("Changes saved successfully");
                        this.setState({selectedPart: undefined, editingPArt: false, parts : undefined},
                            () => this.getParts())
                    } else {
                        var msg = "Error saving part details, please check your connection and try again."
                        alert(msg)

                    }
                }).catch(error => {

                var msg = "Error saving part details. "

                if (error.response) {
                    if (error.response.data.error) {
                        msg = msg + error.response.data.error
                    }
                    alert(msg)
                } else {
                    alert(error)

                }
            })
        } else {
            alert("Please fill in the part name field.")
        }
    }

    render() {

        return (<div>
            <div className="clientsheader">
                <div className="tabs">
                    <div className="selected">{WARRANTY_SECTIONS.PARTS}</div>
                    <div onClick={() => this.props.tabClickHandler(WARRANTY_SECTIONS.BREWERS)}>{WARRANTY_SECTIONS.BREWERS}</div>
                </div>
            </div>
            <div className="cell small-12 admin-wrap">

                <div className="admin-set-box">

                    <div className="cell small-12 title-box">
                        <div className="cell small-4 title"  style={{borderBottom: "0px"}}>Master Parts List </div>
                        <div className="cell small-4">
                            <div className="av-button"  onClick={() => this.newPartClicked()}>Add New Part</div>
                        </div>
                        <div className="cell small-4 search">
                            <input type="text" placeholder="Search"  name="search" value={this.state.search} onChange={this.handleChange}/>
                        </div>
                    </div>



                    <div className="clearfix" />

                </div>



                <div className="cell small-12 no-pad">

                    <TableContainer component={Paper}>
                        <Table  size="small" aria-label="clean results">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Type</TableCell>
                                    <TableCell>Description</TableCell>
                                    <TableCell>Min. Stock</TableCell>
                                    <TableCell> </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {this.state.tableData === undefined ? <TableRow><TableCell>Loading...</TableCell></TableRow> : this.state.tableData.slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage)}
                            </TableBody>
                        </Table>

                    </TableContainer>
                    {this.state.tableData &&
                    <TablePagination
                        rowsPerPageOptions={[10, 25, 100]}
                        component={Paper}
                        count={this.state.tableData === undefined ? 0 : this.state.tableData.length}
                        rowsPerPage={this.state.rowsPerPage}
                        page={this.state.page}
                        onChangePage={(event, newPage) => {

                            this.setState({page: newPage})
                        }}
                        onChangeRowsPerPage={(event) => {
                            this.setState({page: 0, rowsPerPage : parseInt(event.target.value, 10)})
                        }}
                    />}
                </div>
            </div>


            {( this.state.selectedPart !== undefined ) && <div className="modal">
                <div className="modal-box main-wrap">
                    <div>

                        {this.state.selectedPart === -1 ?
                            <div className="cell small-12 small-pad title">Enter Part Details</div>
                            :
                            <div className="cell small-8 small-pad title">Part Details</div>}
                        {(!this.state.editingPart) &&
                        <div className="cell small-4 edit-btn-box small-pad"><div className="av-button" onClick={() => this.editPartClicked()}>Edit</div></div>}
                    </div>

                    <div className="cell small-12 small-pad modal-section">

                        <div className="cell small-12">
                            <span>Part Type <small>(Optional)</small></span>
                            {this.state.editingPart ?
                                <input className="edit-field" type="text" name="edit_partCode" placeholder="Part Type" value={this.state.edit_partCode} onChange={this.handleChange}/>
                                :
                                <div className="field">{this.state.parts[this.state.selectedPart].partCode}</div>

                            }
                        </div>


                        <div className="cell small-12">
                            <span>Part Name</span>
                            {this.state.editingPart ?
                                <input className="edit-field" type="text" name="edit_partName" placeholder="Part Name" value={this.state.edit_partName} onChange={this.handleChange}/>
                                :
                                <div className="field">{this.state.parts[this.state.selectedPart].partName}</div>

                            }
                        </div>

                        <div className="cell small-12">
                            <span>Min Stock Level<small> (Enter 0 if there is no minimum level for this part)</small></span>

                            {this.state.editingPart ?
                                <input className="edit-field" type="text" name="edit_minStock" placeholder="Min. Stock Level" value={this.state.edit_minStock} onChange={this.handleChange}/>
                                :
                                <div className="field"> {parseInt(this.state.parts[this.state.selectedPart].minStock)}</div>

                            }
                        </div>


                    </div>

                    {this.state.editingPart ? <div><div className="av-button" onClick={() => this.savePartClicked()}>Save </div> <div className="av-button" onClick={() => this.hidePartModal()}>Cancel</div></div>
                        :
                        <div className="av-button" onClick={() => this.hidePartModal()}>OK</div>}

                    <div className="clearfix" />
                </div></div>}


        </div>)

            }
}

export default Parts;
