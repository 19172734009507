import React from "react";




class Client extends React.Component {

    constructor(props) {
        super(props);

        var ticks = 0;
        if (this.props.tickMode) {
            this.props.venues.map((venue, c) => {
                if (this.props.tickedLocs.includes(venue.id))
                {
                    ticks++
                }
                return 0
            })
        }
        this.state = {
            expanded: false,
            tickCount: ticks
        };
        //this.handleChange = this.handleChange.bind(this);
    }

    componentWillReceiveProps(nextProps) {

        if (nextProps.tickedLocs) {
            var ticks = 0;
            if (this.props.tickMode) {
                this.props.venues.map((venue, c) => {
                    if (nextProps.tickedLocs.includes(venue.id))
                    {
                        ticks++
                    }
                    return 0
                })
            }
            this.setState({
                tickCount: ticks
            });
        }

    }


    expandOrContract()
    {
        var newExpanded = !this.state.expanded
        this.setState({expanded : newExpanded});

    }

    render() {


        return(<div className="cell small-12 client-item">

            <div className="expandbox">
                <div title="click to expand" className="black-arrow" onClick={() => this.expandOrContract()}>{this.state.expanded ? <span className='innerarrow down'></span> : <span className='innerarrow'></span>}</div>

                <div className="title" onClick={() => this.expandOrContract()}>
                    <div className="cell small-5 no-pad">
                        {(this.props.didSelectClient === undefined) ?
                            <span>{this.props.name}</span>
                            :
                            <span onClick={() => this.props.didSelectClient(this.props.clientID, this.props.name)} style={{textDecoration: "underline", cursor: "pointer"}}>{this.props.name}</span>

                        }

                    </div><div className="cell small-7 no-pad">{this.props.tickMode ? <span>{this.state.tickCount}/{this.props.venues.length}</span> : <span>{this.props.venues.length}/{this.props.locCount}</span>}</div></div>

                {this.state.expanded &&

                <div>
                    {this.props.tickMode && <span><div className="av-button" onClick={()=>this.props.addAllClicked(this.props.idx)}>Add All</div>  <div className="av-button" onClick={()=>this.props.deleteAllClicked(this.props.idx)}>Remove All</div></span>}
                    <div className="cell small-12 location locationhead small-pad">
                        <div className="cell small-1 no-pad" />
                        <div className="cell small-1 no-pad">ID</div>
                        <div className="cell small-10 no-pad">
                            Venue
                        </div>
                    </div>


                    {this.props.venues.map((venue, c) => {


                        return <div className="cell small-12 location small-pad" key={venue.id}>
                            <div className="cell small-1 no-pad" />
                            <div key={c} className="cell small-1 no-pad">
                                {this.props.tickMode &&  <input type="checkbox" checked={this.props.tickedLocs.includes(venue.id)}
                                                                onClick={this.props.tickedLocs.includes(venue.id) ? () => this.props.deleteClicked(this.props.idx,c) :  () => this.props.addClicked(this.props.idx,c)}/>}
                                {venue.id}
                            </div>
                            <div className="cell small-10 no-pad">
                                {this.props.tickMode ?
                                    <span>{venue.locationname}</span>
                                    :
                                    <span onClick={() => this.props.didSelectVenue(this.props.clientID, this.props.name, venue.id, venue.locationname)} style={{textDecoration: "underline", cursor: "pointer"}}>{venue.locationname}</span>

                                }
                            </div>

                            {this.props.deleteMode ?
                                <div className="cell small-1 no-pad delete" onClick={() => this.props.deleteClicked(this.props.idx,c)}>
                                    &nbsp;
                                </div>
                                :
                                <div className="cell small-1 no-pad">
                                    &nbsp;
                                </div>
                            }
                        </div>

                    })}


                </div>
                }
            </div>
        </div>);
    }
}

export default Client;
