import React from "react";
import {WARRANTY_SECTIONS} from "../../../resources/strings";
import TableContainer from "@material-ui/core/TableContainer";
import Paper from "@material-ui/core/Paper/Paper";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell/TableCell";
import TablePagination from "@material-ui/core/TablePagination";
import Axios from "axios";
import {API} from "../../../api/APIClient";
import SelectSearch from "react-select-search"


class Brewers extends React.Component {


    constructor(props) {
        super(props);

        this.state = {
            page: 0,
            partsPage: 0,
            rowsPerPage: 25
        };
        this.handleChange = this.handleChange.bind(this);
        this.masterPartDidChange = this.masterPartDidChange.bind(this);

    }

    masterPartDidChange(event) {
        if (event === "all") {
            event = undefined
        }
        this.setState({
            selectedMasterPart: event
        });

    }

    handleChange(event) {

        const target = event.target;
        const name = target.name;

        this.setState({
            [name]: target.value
        }, ()=>this.updateMatching());



    }



    componentDidMount() {
        setTimeout(
            () => this.getBrewers(),
            100
        );
        setTimeout(
            () => this.getMasterParts(),
            200
        );
    }


    getMasterParts() {
        const formData = new FormData();

        Axios
            .post(API.warranty.getParts, formData)
            .then((res) => {

                if (res.data.success) {

                    let mpts = res.data.parts.map((part, c) => {

                        return {"name":part.partName, "value" : part.id }


                    })

                    this.setState({
                        masterParts: mpts
                    })


                }
            })
    }

    getBrewerParts(brewerID) {
        const formData = new FormData();
        formData.append('brewerID', brewerID);

        Axios
            .post(API.warranty.getBrewerParts, formData)
            .then((res) => {

                if (res.data.success) {
                    let allowedMasterParts = []
                    for (let c=0; c < this.state.masterParts.length; c++) {
                        if (! res.data.parts.some(p => (p.id === this.state.masterParts[c].value))) {
                            allowedMasterParts.push(this.state.masterParts[c])
                        }
                    }

                    this.setState({
                        brewerParts: res.data.parts,
                        allowedMasterParts: allowedMasterParts
                    }, () => this.updateBrewerParts())


                }
            })
    }

    getBrewers() {
        const formData = new FormData();

        Axios
            .post(API.warranty.getBrewers, formData)
            .then((res) => {

                if (res.data.success) {

                    this.setState({
                        brewers: res.data.brewers,
                    }, () => this.updateMatching())


                }
            })
    }

    hideBrewerModal() {
        this.setState({selectedBrewer: undefined, editingBrewer: false})
    }

    handleBrewerDetailsClick(indx) {
        this.setState({selectedBrewer: indx})
    }

    handleBrewerPartsClick(brewer) {
        this.setState({selectedPartsEditBrewer: brewer})
        this.getBrewerParts(brewer.id)
    }


    editBrewerClicked()
    {
        this.setState({editingBrewer: true,
            edit_brewerName: this.state.brewers[this.state.selectedBrewer].brewerName,
            edit_amsAgreement: this.state.brewers[this.state.selectedBrewer].amsAgreement,
            edit_maxFixes: this.state.brewers[this.state.selectedBrewer].maxFixes,
        })
    }

    newBrewerClicked()
    {
        this.setState({selectedBrewer: -1,
            editingBrewer: true,
            edit_brewerName: "",
            edit_amsAgreement: "0",
            edit_maxFixes: "0"
        })
    }

    updateBrewerParts() {

        this.setState({
            partsTableData: this.state.brewerParts.map((part, c) => {

                return <TableRow key={c}>
                    <TableCell component="th" scope="row">{part.partCode}</TableCell>
                    <TableCell component="th" scope="row">{part.partName}</TableCell>
                    <TableCell component="th" scope="row">{part.minStock}</TableCell>
                    <TableCell>
                        <div onClick={() => this.removePartClicked(part.id)} className="av-button" style={{padding: "2px 30px"}}>Remove</div>

                    </TableCell>
                </TableRow>

            })
        })
    }



    updateMatching() {
        var matchingBrewers = []
        for (var c = 0; c < this.state.brewers.length; c++) {
            if (! this.state.search || this.state.brewers[c].brewerName.toLowerCase().includes(this.state.search.toLowerCase()) ){
                matchingBrewers.push(this.state.brewers[c])
            }
        }

        this.setState({
            tableData: matchingBrewers.map((brewer, c) => {

                return <TableRow key={c}>
                    <TableCell component="th" scope="row">{brewer.brewerName}</TableCell>
                    <TableCell component="th" scope="row">{brewer.amsAgreement === "1" ? "Yes" : "No"}</TableCell>
                    <TableCell component="th" scope="row">{brewer.maxFixes}</TableCell>
                    <TableCell>
                        <div onClick={() => this.handleBrewerDetailsClick(c)} className="av-button" style={{padding: "2px 30px"}}>Edit</div>
                        <div onClick={() => this.handleBrewerPartsClick(brewer)} className="av-button" style={{padding: "2px 30px"}}>Assign parts</div>
                        <div onClick={() => this.deleteBrewer(brewer.id)} className="av-button" style={{padding: "2px 30px"}}>Delete</div>
                    </TableCell>
                </TableRow>

            })
        })
    }


    deleteBrewer(brewerID) {
        if (window.confirm("Are you sure? This can not be undone!")) {
            const formData = new FormData();

            formData.append('brewerID', brewerID);


            Axios
                .post(API.warranty.deleteBrewer, formData)
                .then((res) => {

                    if (res.data.success) {

                        this.setState({selectedBrewer: undefined, editingBrewer: false, brewers : undefined},
                            () => this.getBrewers())

                    } else {
                        var msg = "Error deleting brewer, please try again."
                        alert(msg)

                    }
                }).catch(error => {

                var msg = "Error in brewer deletion."

                if (error.response) {
                    if (error.response.data.error) {

                        msg = msg + error.response.data.error

                    }

                    alert(msg)
                } else {
                    alert(error)

                }
            })
        }
    }


    saveBrewerClicked() {
        if (this.state.edit_brewerName) {
            const formData = new FormData();
            if (this.state.selectedBrewer === -1) {
                formData.append('brewerID', -1);

            } else {
                formData.append('brewerID', this.state.brewers[this.state.selectedBrewer].id);

            }
            formData.append('brewerName', this.state.edit_brewerName);
            formData.append('amsAgreement', this.state.edit_amsAgreement);
            formData.append('maxFixes', this.state.edit_maxFixes);

            Axios
                .post(API.warranty.editBrewer, formData)
                .then((res) => {

                    if (res.data.success) {

                        setTimeout(
                            () =>         alert("Changes saved successfully"),
                            100
                        );

                        this.setState({selectedBrewer: undefined, editingBrewer: false, brewers : undefined},
                            () => this.getBrewers())
                    } else {
                        var msg = "Error saving brewer details, please check your connection and try again."
                        alert(msg)

                    }
                }).catch(error => {

                var msg = "Error saving brewer details. "

                if (error.response) {
                    if (error.response.data.error) {
                        msg = msg + error.response.data.error
                    }
                    alert(msg)
                } else {
                    alert(error)

                }
            })
        } else {
            alert("Please fill in the brewer name field.")
        }
    }

    cancelPartsEditClicked()
    {
        this.setState({selectedPartsEditBrewer : undefined})
    }


    addPartClicked() {


        if (this.state.selectedMasterPart) {
            //add via API
            const formData = new FormData();
            formData.append('brewerID', this.state.selectedPartsEditBrewer.id);
            formData.append('partID', this.state.selectedMasterPart);

            Axios
                .post(API.warranty.assignPartToBrewer, formData)
                .then((res) => {

                    if (res.data.success) {

                        this.setState({
                            selectedMasterPart: undefined,
                            brewerParts: undefined,
                            partsTableData: undefined
                        }, () => this.onPartAdded())


                    }
                })
        }


    }

    onPartAdded() {
        this.getBrewerParts(this.state.selectedPartsEditBrewer.id)
        setTimeout(
            () =>         alert("Changes saved successfully"),
            100
        );
    }

    removePartClicked(partID) {

        //add via API
        const formData = new FormData();
        formData.append('brewerID', this.state.selectedPartsEditBrewer.id);
        formData.append('partID', partID);

        Axios
            .post(API.warranty.removePartFromBrewer, formData)
            .then((res) => {

                if (res.data.success) {

                    this.setState({
                        selectedMasterPart: undefined,
                        brewerParts: undefined,
                        partsTableData: undefined
                    }, () => this.getBrewerParts(this.state.selectedPartsEditBrewer.id))
                    setTimeout(
                        () =>         alert("Changes saved successfully"),
                        100
                    );
                }
            })

    }

    render() {

        if (this.state.selectedPartsEditBrewer) {
            return (<div> <div className="clientsheader">
                <div className="tabs">
                    <div onClick={() => this.props.tabClickHandler(WARRANTY_SECTIONS.PARTS)}>{WARRANTY_SECTIONS.PARTS}</div>
                    <div className="selected">{WARRANTY_SECTIONS.BREWERS}</div>

                    </div>
                </div>
                <div className="cell small-12 admin-wrap">

                    <div><div className="av-button" onClick={() => this.cancelPartsEditClicked()}>Back to Brewers list </div></div>

                    <div className="admin-set-box">

                        <div className="cell small-12 title-box">
                            <div className="cell small-4 title"  style={{borderBottom: "0px"}}>{this.state.selectedPartsEditBrewer.brewerName} parts </div>
                            <div className="cell small-2" style={{textAlign:"right"}}> Assign part: </div>
                            <div className="cell small-6">

                                <SelectSearch
                                    options={this.state.allowedMasterParts}
                                    search
                                    onChange={this.masterPartDidChange}
                                    name={"selectedMasterPart"}
                                    value={ this.state.selectedMasterPart}
                                    //filterOptions={fuzzySearch}
                                    placeholder="Select a part to add"
                                />&nbsp;&nbsp;&nbsp;<div className="av-button"  onClick={() => this.addPartClicked()}>Add</div>

                            </div>
                        </div>

                        <div className="clearfix" />
                    </div>

                    <div className="cell small-12 no-pad">

                        <TableContainer component={Paper}>
                            <Table  size="small" aria-label="clean results">
                                <TableHead>
                                    <TableCell>Type</TableCell>
                                    <TableCell>Description</TableCell>
                                    <TableCell>Min. Stock</TableCell>
                                    <TableCell> </TableCell>
                                </TableHead>
                                <TableBody>
                                    {this.state.partsTableData === undefined ? <TableRow><TableCell>Loading...</TableCell></TableRow> : this.state.partsTableData.slice(this.state.partsPage * this.state.rowsPerPage, this.state.partsPage * this.state.rowsPerPage + this.state.rowsPerPage)}
                                </TableBody>
                            </Table>

                        </TableContainer>
                        {this.state.partsTableData &&
                        <TablePagination
                            rowsPerPageOptions={[10, 25, 100]}
                            component={Paper}
                            count={this.state.partsTableData === undefined ? 0 : this.state.partsTableData.length}
                            rowsPerPage={this.state.rowsPerPage}
                            page={this.state.partsPage}
                            onChangePage={(event, newPage) => {

                                this.setState({page: newPage})
                            }}
                            onChangeRowsPerPage={(event) => {
                                this.setState({partsPage: 0, rowsPerPage : parseInt(event.target.value, 10)})
                            }}
                        />}
                    </div>
                </div>
            </div>)
        } else {
            return (<div>
                <div className="clientsheader">
                    <div className="tabs">
                        <div onClick={() => this.props.tabClickHandler(WARRANTY_SECTIONS.PARTS)}>{WARRANTY_SECTIONS.PARTS}</div>
                        <div className="selected">{WARRANTY_SECTIONS.BREWERS}</div>

                    </div>
                </div>
                <div className="cell small-12 admin-wrap">

                    <div className="admin-set-box">

                        <div className="cell small-12 title-box">
                            <div className="cell small-4 title"  style={{borderBottom: "0px"}}>Lead Brewers </div>
                            <div className="cell small-4">
                                <div className="av-button"  onClick={() => this.newBrewerClicked()}>Add New Lead Brewer</div>
                            </div>
                            <div className="cell small-4 search">
                                <input type="text" placeholder="Search"  name="search" value={this.state.search} onChange={this.handleChange}/>
                            </div>
                        </div>



                        <div className="clearfix" />

                    </div>



                    <div className="cell small-12 no-pad">

                        <TableContainer component={Paper}>
                            <Table  size="small" aria-label="clean results">
                                <TableHead>
                                    <TableCell>Brewer</TableCell>
                                    <TableCell>AMS Agreement</TableCell>
                                    <TableCell>Max. Fixes</TableCell>
                                    <TableCell> </TableCell>
                                </TableHead>
                                <TableBody>
                                    {this.state.tableData === undefined ? <TableRow><TableCell>Loading...</TableCell></TableRow> : this.state.tableData.slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage)}
                                </TableBody>
                            </Table>

                        </TableContainer>
                        {this.state.tableData &&
                        <TablePagination
                            rowsPerPageOptions={[10, 25, 100]}
                            component={Paper}
                            count={this.state.tableData === undefined ? 0 : this.state.tableData.length}
                            rowsPerPage={this.state.rowsPerPage}
                            page={this.state.page}
                            onChangePage={(event, newPage) => {

                                this.setState({page: newPage})
                            }}
                            onChangeRowsPerPage={(event) => {
                                this.setState({page: 0, rowsPerPage : parseInt(event.target.value, 10)})
                            }}
                        />}
                    </div>
                </div>


                {( this.state.selectedBrewer !== undefined ) && <div className="modal">
                    <div className="modal-box main-wrap">
                        <div>

                            {this.state.selectedBrewer === -1 ?
                                <div className="cell small-12 small-pad title">Enter Brewer Details</div>
                                :
                                <div className="cell small-8 small-pad title">Brewer Details</div>}
                            {(!this.state.editingBrewer) &&
                            <div className="cell small-4 edit-btn-box small-pad"><div className="av-button" onClick={() => this.editBrewerClicked()}>Edit</div></div>}
                        </div>

                        <div className="cell small-12 small-pad modal-section">

                            <div className="cell small-12">
                                <span>Brewer Name</span>
                                {this.state.editingBrewer ?
                                    <input className="edit-field" type="text" name="edit_brewerName" placeholder="Brewer Name" value={this.state.edit_brewerName} onChange={this.handleChange}/>
                                    :
                                    <div className="field">{this.state.brewers[this.state.selectedBrewer].brewerName}</div>

                                }
                            </div>

                            <div className="cell small-12">
                                <span>AMS Agreement in place</span>

                                {this.state.editingBrewer ?
                                    <select className="edit-field" value={this.state.edit_amsAgreement} name="edit_amsAgreement" onChange={this.handleChange}>
                                        <option value="1">Yes</option>
                                        <option value="0">No</option>
                                    </select>
                                    :
                                    <div className="field"> {this.state.brewers[this.state.selectedBrewer].amsAgreement === "1" ? "Yes" : "No"}</div>

                                }
                            </div>

                            <div className="cell small-12">
                                <span>Max. fixes allowed <small>(enter '0' for unlimited fixes)</small></span>

                                {this.state.editingBrewer ?
                                    <input className="edit-field" type="text" name="edit_maxFixes" placeholder="enter a number" value={this.state.edit_maxFixes} onChange={this.handleChange}/>
                                    :
                                    <div className="field"> {this.state.brewers[this.state.selectedBrewer].maxFixes}</div>

                                }
                            </div>


                        </div>

                        {this.state.editingBrewer ? <div><div className="av-button" onClick={() => this.saveBrewerClicked()}>Save </div> <div className="av-button" onClick={() => this.hideBrewerModal()}>Cancel</div></div>
                            :
                            <div className="av-button" onClick={() => this.hideBrewerModal()}>OK</div>}

                        <div className="clearfix" />
                    </div></div>}
            </div>)
        }



    }
}

export default Brewers;
