import React from "react";
import Axios from "axios";
import {API} from "../../../api/APIClient";

class Bar extends React.Component{

    constructor(props) {
        super(props);

        this.state = {
            expanded: false
        };
    }



    expandOrContract()
    {
        var newExpanded = !this.state.expanded
        this.setState({expanded : newExpanded});
        if (newExpanded && this.state.taps === undefined){
            this.getTaps()
        }

    }


    componentDidMount()
    {
        this.getTaps()
    }


    getTaps() {
        const formData = new FormData();
        formData.append('barID', this.props.id);

        Axios
            .post(API.locations.getTaps, formData)
            .then((res) => {

                if (res.data.success) {

                    this.setState({
                        taps: res.data.data
                    })
                }
            })
    }


    componentWillReceiveProps(nextProps) {

        if (nextProps.barToRefresh === this.props.index) {
            this.getTaps()
        }
    }


    render() {
        return <div className="cell small-12 no-pad">

            <div className="expandbox">
                <div title="click to expand" className="black-arrow" onClick={() => this.expandOrContract()}>{this.state.expanded ? <span className='innerarrow down'></span> : <span className='innerarrow'></span>}</div>

                <span className="title"  title="click to expand" onClick={() => this.expandOrContract()}>{this.props.name}</span>
                {this.state.taps && <span>{this.state.taps.length} tap{this.state.taps.length !== 1 && "s"}</span>}
                <div className="bar-btns" ><span onClick={()=> this.props.newTapClicked(this.props.index )}   className="av-button">Add tap</span> <span className="av-button" onClick={()=>this.props.detailsClicked(this.props.index)}>Details</span> <span onClick={()=>this.props.deleteClicked(this.props.index)} className="av-button">Delete bar</span></div>

                {(this.state.expanded && this.state.taps) && 

                    <div className="cell small-12">

                        <div className="cell small-12 tap tap-head small-pad">

                            <div className="cell small-1 no-pad">Tap Number</div>
                            <div className="cell small-6 no-pad">Product Name</div>
                            <div className="cell small-5 no-pad">Actions</div>

                        </div>

                    {this.state.taps.map((tap, c) => {

                        return <div className="cell small-12 tap small-pad">

                            <div className="cell small-1 no-pad">
                                {tap.linenumber}
                            </div>

                            <div className="cell small-6 no-pad ">
                                {tap.product}
                            </div>

                            <div className="cell small-5 no-pad">
                                <div onClick={()=> this.props.editTapClicked(tap.id, tap.linenumber, tap.product_id,this.props.index)} style={{padding: "2px 30px"}} className="av-button">Edit Tap</div>
                                <div onClick={()=> this.props.deleteTapClicked(tap.id, this.props.index )} style={{padding: "2px 30px"}} className="av-button">Delete Tap</div>
                            </div>
                        </div>

                    })}

                </div>}




            </div>
        </div>

            }
}

export default Bar