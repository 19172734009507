import Axios from "axios";
import {API} from "../../api/APIClient"
import React from "react";
import Fault from "./Fault";


class FixSuggestions extends React.Component {


    constructor(props) {
        super(props);

        this.state = {
            faults: undefined
        };
        //this.handleChange = this.handleChange.bind(this);
    }


    componentDidMount() {
        this.getFaultsList()

    }

    getFaultsList() {
        const formData = new FormData();

        Axios
            .post(API.faults.getFaultList, formData)
            .then((res) => {

                if (res.data.success) {

                    this.setState({
                        faults: res.data.data
                    })
                }
            })
    }



    render() {

        return (<div>
            {this.state.faults === undefined ?

                <div>Loading...</div>

                :
                <div className="cell small-12 small-pad details-wrap">

                    <div className="deets_sect">
                        <div className="cell small-12 small-pad">
                            <div className="title">Faults and Product links</div>
                        </div>

                    
                    <div className="fix-list">
                    {this.state.faults.map((fault, c) => {

                        return <Fault  key={c} id={fault.id} userid={this.props.userID}
                                       name={fault.description}
                        />;
                    })}
                    
                    </div>
                    <div className="clearfix" />
                    </div>

                     

                </div>


            }

        </div>);
    }
}

export default FixSuggestions;