import React from 'react'
import AdminHeader from './AdminHeader'
import MiniNotifications from './MiniNotifications'
import AdminMenuGrid from  './AdminMenuGrid'
import {
    ADMIN_SECTIONS,
    BUTTON_MSG,
    PART_REPORT_SECTIONS,
    SECTIONS,
    WARRANTY_SECTIONS
} from "../../resources/strings";
import UsersTable from "./UsersTable";
import UserDetails from "./users/UserDetails"
import FixSuggestions from "./FixSuggestions";
import CSVSection from "./CSVSection";
import Products from "./Products";
import Feedback from "./Feedback";
import FeedbackDetail from "./FeedbackDetail";
import Approvals from "./Approvals";
import VisitDetails from "../clients/visit/VisitDetails";
import ClientAdmin from "./ClientAdmin";
import ClientDetails from "./clients/ClientDetails";
import VenueDetails from "./clients/VenueDetails";
import Axios from "axios";
import {API} from "../../api/APIClient";
import Parts from "./warranty/Parts";
import Brewers from "./warranty/Brewers";
import BrewerReport from "./warranty/BrewerReport";
import TechnicianReport from "./warranty/TechnicianReport";

class Admin extends React.Component {

    constructor(props) {
        super(props);


        var currScrn = ADMIN_SECTIONS.HOME
        var feedbackItemID = undefined
        var selectedClientID = undefined
        var selectedVenueID = undefined
        var buttonMessage = undefined
        if (this.props.match.params.id)
        {
            const msgID = parseInt(this.props.match.params.id)
            if (! isNaN(msgID)){
                currScrn = ADMIN_SECTIONS.FEEDBACK
                feedbackItemID = msgID
            }
        }
        if (this.props.match.params.vcode)
        {
            const cli_ven = this.props.match.params.vcode.split("_")
            currScrn = ADMIN_SECTIONS.CLIENTS
            selectedClientID = cli_ven[0]
            selectedVenueID = cli_ven[1]
        }

        this.state = {
            currentScreen: currScrn,
            selectedUserID: undefined,
            selectedClientID: selectedClientID,
            selectedVenueID: selectedVenueID,
            buttonMessage: buttonMessage,
            feedbackItemID: feedbackItemID,
            approvalItemID: undefined,
            selectedApprovalVisitDetails: undefined,
            warrantySection: WARRANTY_SECTIONS.PARTS,
            partsReportSection: PART_REPORT_SECTIONS.BREWER_USAGE
        };

    }




    componentDidMount()
    {
        this.props.sectionChangeHandler(SECTIONS.ADMIN)

    }

    handleClientNotifClick(msgID){


        this.setState({
            currentScreen: ADMIN_SECTIONS.FEEDBACK,
            selectedUserID: undefined,
            selectedClientID: undefined,
            selectedVenueID: undefined,
            feedbackItemID: msgID,
            buttonMessage: undefined,
            approvalItemID: undefined,
            selectedApprovalVisitDetails: undefined
        });
    }

    handleWarrantyTabClick(tab) {
        this.setState({
            warrantySection: tab
        })
    }


    handlePartsReportTabClick(tab) {
        this.setState({
            partsReportSection: tab
        })
    }

    handleItemClick(newScreen)
    {

        this.setState({
            currentScreen: newScreen,
            selectedUserID: undefined,
            selectedClientID: undefined,
            selectedVenueID: undefined,
            feedbackItemID: undefined,
            buttonMessage: undefined,
            approvalItemID: undefined,
            selectedApprovalVisitDetails: undefined
        });
    }

    handleClientDetailsClick()
    {

        this.setState({
            selectedUserID: undefined,
            selectedVenueID: undefined
        });
    }

    handleVenueDetailsClick()
    {
        this.setState({
            selectedUserID: undefined,
        });
    }


    handleUserSelected(userID)
    {
        if (userID === -1)
        {
            this.setState({
                selectedUserID: userID,
                buttonMessage: BUTTON_MSG.EDIT_USER
            });
        } else {
            this.setState({
                selectedUserID: userID
            });

        }
    }

    handleClientSelected(clientID)
    {
        if (clientID === -1)
        {
            this.setState({
                selectedClientID: clientID,
                buttonMessage: BUTTON_MSG.EDIT_CLIENT
            });
        } else {
            this.setState({
                selectedClientID: clientID
            });

        }
    }

    handleVenueSelected(venueID)
    {
        if (venueID === -1)
        {
            this.setState({
                selectedVenueID: venueID,
                buttonMessage: BUTTON_MSG.EDIT_VENUE
            });
        } else {
            this.setState({
                selectedVenueID: venueID
            });

        }
    }


    handleButtonMessage(msg) {
        this.setState({
            buttonMessage: msg
        });
    }

    handleFeedbackItemClick(id)
    {
        this.setState({feedbackItemID: id})
    }

    handleApprovalItemClick(id)
    {

        this.setState({
            approvalItemID: id,
            selectedApprovalVisitDetails: undefined

        });
        this.getSelectedVisit(id)
    }


    didSelectFault(faultID)
    {
        this.setState({selectedFault: faultID})
        this.getFaultDetails(faultID)

    }

    getFaultDetails(faultID){

        const formData = new FormData();
        formData.append('faultID', faultID);


        Axios
            .post(API.clients.getFaultDetails, formData)
            .then((res) => {

                if (res.data.success) {

                    this.setState({

                        faultDetails: res.data.data
                    })
                }
            })
    }

    approveVisit(visitID){
        const formData = new FormData();
        formData.append('visitID', visitID);
        formData.append('approved', true);

        Axios
            .post(API.visits.approveVisit, formData)
            .then((res) => {
                if (res.data.success) {

                        this.setState({
                            selectedApprovalRequired: undefined
                        })
                }
            })
    }


    getSelectedVisit(visitID) {
        const formData = new FormData();
        formData.append('visitID', visitID);

        Axios
            .post(API.locations.getVisit, formData)
            .then((res) => {
                if (res.data.success) {

                    if (res.data.visit === false) {

                    } else {

                        this.setState({
                            selectedApprovalVisitDetails: res.data.visit,
                            selectedApprovalRequired: visitID
                        })
                    }

                }
            })
    }


    render(){

        switch (this.state.currentScreen) {

            case ADMIN_SECTIONS.HOME :

                return(
                    <div className="main-wrap settings-section">
                        <AdminHeader user={this.props.user}
                                     screen={this.state.currentScreen}
                        />

                        {this.props.user.admin === "1" && <MiniNotifications unreadUpdater={this.props.unreadUpdater}/>}
                        <AdminMenuGrid user={this.props.user} itemClickHandler={(screen) => this.handleItemClick(screen)}/>
                    </div>
                );

            case ADMIN_SECTIONS.USERS :

                return(
                    <div className="main-wrap settings-section">
                        <AdminHeader user={this.props.user}
                                     buttonMessage={this.state.buttonMessage}
                            screen={this.state.currentScreen}
                            itemClickHandler={(screen) => this.handleItemClick(screen)}
                            selectedUserID={this.state.selectedUserID}
                            buttonMessageHandler={(msg) => this.handleButtonMessage(msg)}

                        />
                        <div>
                            {this.state.selectedUserID === undefined ?
                                <UsersTable
                                    didSelectUser={(userID) => this.handleUserSelected(userID)}
                                    selectedUserID={this.state.selectedUserID}
                                />
                            :
                                <UserDetails buttonMessage={this.state.buttonMessage}
                                             buttonMessageHandler={(msg) => this.handleButtonMessage(msg)}
                                             userID={this.state.selectedUserID}
                                             itemClickHandler={(screen) => this.handleItemClick(screen)}
                                                 didSelectUser={(userID) => this.handleUserSelected(userID)}
                                />
                            }

                        </div>
                    </div>
                );

            case ADMIN_SECTIONS.FIX_SUGGESTIONS :
                return(
                    <div className="main-wrap settings-section">
                        <AdminHeader user={this.props.user}
                                     buttonMessage={this.state.buttonMessage}
                            screen={this.state.currentScreen}
                            itemClickHandler={(screen) => this.handleItemClick(screen)}
                            buttonMessageHandler={(msg) => this.handleButtonMessage(msg)}

                        />
                        <div>
                                <FixSuggestions />
                        </div>
                    </div>
                );
            case ADMIN_SECTIONS.CSVS :
                return(
                    <div className="main-wrap settings-section">
                        <AdminHeader user={this.props.user}
                                     buttonMessage={this.state.buttonMessage}
                            screen={this.state.currentScreen}
                            itemClickHandler={(screen) => this.handleItemClick(screen)}
                            buttonMessageHandler={(msg) => this.handleButtonMessage(msg)}

                        />
                        <div>

                            <CSVSection />

                        </div>
                    </div>);
            case ADMIN_SECTIONS.PRODUCTS :
                return(
                    <div className="main-wrap settings-section">
                        <AdminHeader user={this.props.user}
                                     buttonMessage={this.state.buttonMessage}
                                     screen={this.state.currentScreen}
                                     itemClickHandler={(screen) => this.handleItemClick(screen)}
                                     buttonMessageHandler={(msg) => this.handleButtonMessage(msg)}
                        />
                        <div>
                            <Products user={this.props.user}/>
                        </div>
                    </div>);
            case ADMIN_SECTIONS.PARTS :
                return(
                    <div className="main-wrap settings-section">
                        <AdminHeader user={this.props.user}
                                     buttonMessage={this.state.buttonMessage}
                                     screen={this.state.currentScreen}
                                     itemClickHandler={(screen) => this.handleItemClick(screen)}
                                     buttonMessageHandler={(msg) => this.handleButtonMessage(msg)}
                        />
                        {this.state.warrantySection == WARRANTY_SECTIONS.PARTS ?
                            <Parts tabClickHandler={(screen) => this.handleWarrantyTabClick(screen)} /> : <Brewers tabClickHandler={(screen) => this.handleWarrantyTabClick(screen)} /> }
                    </div>);

            case ADMIN_SECTIONS.PARTS_REPORTS :
                return(
                    <div className="main-wrap settings-section">
                        <AdminHeader user={this.props.user}
                                     buttonMessage={this.state.buttonMessage}
                                     screen={this.state.currentScreen}
                                     itemClickHandler={(screen) => this.handleItemClick(screen)}
                                     buttonMessageHandler={(msg) => this.handleButtonMessage(msg)}
                        />
                        {this.state.partsReportSection == PART_REPORT_SECTIONS.BREWER_USAGE ?
                            <BrewerReport tabClickHandler={(screen) => this.handlePartsReportTabClick(screen)} /> : <TechnicianReport tabClickHandler={(screen) => this.handlePartsReportTabClick(screen)} /> }
                    </div>);
            case ADMIN_SECTIONS.FEEDBACK :
                return(
                    <div className="main-wrap settings-section">
                        <AdminHeader user={this.props.user}
                                     feedbackItemID={this.state.feedbackItemID}
                            buttonMessage={this.state.buttonMessage}
                            screen={this.state.currentScreen}
                            itemClickHandler={(screen) => this.handleItemClick(screen)}
                            buttonMessageHandler={(msg) => this.handleButtonMessage(msg)}

                        />
                        <div>
                            {(this.state.feedbackItemID === undefined) ?  <Feedback itemClickHandler={(id) => this.handleFeedbackItemClick(id)}/> : <FeedbackDetail itemToView={this.state.feedbackItemID} />}
                        </div>
                    </div>);
            case ADMIN_SECTIONS.APPROVALS :
                return(
                    <div className="main-wrap settings-section">
                        <AdminHeader user={this.props.user}
                                     approvalItemID={this.state.approvalItemID}
                            buttonMessage={this.state.buttonMessage}
                            screen={this.state.currentScreen}
                            itemClickHandler={(screen) => this.handleItemClick(screen)}
                            buttonMessageHandler={(msg) => this.handleButtonMessage(msg)}

                        />
                        <div>
                            {(this.state.selectedApprovalVisitDetails === undefined) ?  <Approvals itemClickHandler={(id) => this.handleApprovalItemClick(id)}/>
                            :
                                <div>
                                    {this.state.selectedApprovalRequired && <div onClick={() => this.approveVisit(this.state.selectedApprovalRequired)} style={{float:"right", margin: "8px"}} className="av-button">Approve</div>}
                                    <VisitDetails visit={this.state.selectedApprovalVisitDetails}  didSelectFault={this.props.didSelectFault} />
                                </div>}
                        </div>
                    </div>);
            case ADMIN_SECTIONS.CLIENTS :
                return(
                    <div className="main-wrap settings-section">
                        <AdminHeader user={this.props.user}
                                     selectedClientID={this.state.selectedClientID}
                                     selectedVenueID={this.state.selectedVenueID}
                                     buttonMessage={this.state.buttonMessage}
                                     screen={this.state.currentScreen}
                                     selectedUserID={this.state.selectedUserID}
                                     itemClickHandler={(screen) => this.handleItemClick(screen)}
                                     buttonMessageHandler={(msg) => this.handleButtonMessage(msg)}
                                     clientDetailsClickHandler={(msg) => this.handleClientDetailsClick(msg)}
                                     venueDetailsClickHandler={(msg) => this.handleVenueDetailsClick(msg)}
                                     didSelectVenue={(id) => this.handleVenueSelected(id)}

                        />
                        <div>
                            {(this.state.selectedClientID === undefined) ?
                                <ClientAdmin itemClickHandler={(id) => this.handleClientSelected(id)}
                                             didSelectClient={(id) => this.handleClientSelected(id)}
                                />

                                :
                                <div>
                                    {(this.state.selectedVenueID === undefined) ?

                                        this.state.selectedUserID === undefined ?
                                                <ClientDetails buttonMessage={this.state.buttonMessage}
                                                               buttonMessageHandler={(msg) => this.handleButtonMessage(msg)}
                                                               clientID={this.state.selectedClientID}
                                                               itemClickHandler={(screen) => this.handleItemClick(screen)}
                                                               didSelectClient={(id) => this.handleClientSelected(id)}
                                                               didSelectVenue={(id) => this.handleVenueSelected(id)}
                                                               didSelectUser={(userID) => this.handleUserSelected(userID)}

                                                />
                                                :
                                                <UserDetails buttonMessage={this.state.buttonMessage}
                                                             buttonMessageHandler={(msg) => this.handleButtonMessage(msg)}
                                                             userID={this.state.selectedUserID}
                                                             itemClickHandler={(screen) => this.handleItemClick(screen)}
                                                             didSelectUser={(userID) => this.handleUserSelected(userID)}
                                                />


                                        :

                                        this.state.selectedUserID === undefined ?
                                            <VenueDetails buttonMessage={this.state.buttonMessage}
                                                          buttonMessageHandler={(msg) => this.handleButtonMessage(msg)}
                                                          venueID={this.state.selectedVenueID}
                                                          itemClickHandler={(screen) => this.handleItemClick(screen)}
                                                          didSelectVenue={(id) => this.handleVenueSelected(id)}
                                                          didSelectClient={(id) => this.handleClientSelected(id)}
                                                          didSelectUser={(userID) => this.handleUserSelected(userID)}
                                                          clientID={this.state.selectedClientID}
                                            />
                                            :
                                            <UserDetails buttonMessage={this.state.buttonMessage}
                                                         buttonMessageHandler={(msg) => this.handleButtonMessage(msg)}
                                                         userID={this.state.selectedUserID}
                                                         itemClickHandler={(screen) => this.handleItemClick(screen)}
                                                         didSelectUser={(userID) => this.handleUserSelected(userID)}
                                            />





                                    }
                                </div>

                            }

                        </div>
                    </div>);
            default :
                return(<div>unknown screen ({this.state.currentScreen})</div>);
        }
    }


}

export default Admin;
