import React from 'react'
import {ADMIN_SECTIONS} from "../../resources/strings";

function AdminMenuGrid(props) {

    if (props.user.admin === "1") {
        return(
            
            <div className="admingrid">
            
                <div className="cell small-4"><div className="gridcell"><div className="title">{ADMIN_SECTIONS.USERS}</div><div className="av-button" onClick={() => props.itemClickHandler(ADMIN_SECTIONS.USERS)}>View</div></div></div>
                <div className="cell small-4"><div className="gridcell"><div className="title">{ADMIN_SECTIONS.CLIENTS}</div><div className="av-button"  onClick={() => props.itemClickHandler(ADMIN_SECTIONS.CLIENTS)}>View</div></div></div>
                <div className="cell small-4"><div className="gridcell"><div className="title">{ADMIN_SECTIONS.FIX_SUGGESTIONS}</div><div className="av-button" onClick={() => props.itemClickHandler(ADMIN_SECTIONS.FIX_SUGGESTIONS)}>View</div></div></div>
                <div className="cell small-4"><div className="gridcell"><div className="title">{ADMIN_SECTIONS.APPROVALS}</div><div className="av-button" onClick={() => props.itemClickHandler(ADMIN_SECTIONS.APPROVALS)}>View</div></div></div>
                <div className="cell small-4"><div className="gridcell"><div className="title">{ADMIN_SECTIONS.FEEDBACK}</div><div className="av-button" onClick={() => props.itemClickHandler(ADMIN_SECTIONS.FEEDBACK)}>View</div></div></div>
                <div className="cell small-4"><div className="gridcell"><div className="title">{ADMIN_SECTIONS.CSVS}</div><div className="av-button" onClick={() => props.itemClickHandler(ADMIN_SECTIONS.CSVS)}>View</div></div></div>
                <div className="cell small-4"><div className="gridcell"><div className="title">{ADMIN_SECTIONS.PRODUCTS}</div><div className="av-button" onClick={() => props.itemClickHandler(ADMIN_SECTIONS.PRODUCTS)}>View</div></div></div>
                <div className="cell small-4"><div className="gridcell"><div className="title">{ADMIN_SECTIONS.PARTS}</div><div className="av-button" onClick={() => props.itemClickHandler(ADMIN_SECTIONS.PARTS)}>View</div></div></div>
                <div className="cell small-4"><div className="gridcell"><div className="title">{ADMIN_SECTIONS.PARTS_REPORTS}</div><div className="av-button" onClick={() => props.itemClickHandler(ADMIN_SECTIONS.PARTS_REPORTS)}>View</div></div></div>
            </div>

        );
    } else {
        return(
            <div className="admingrid">
                <div className="cell small-4"><div className="gridcell"><div className="title">{ADMIN_SECTIONS.CLIENTS}</div><div className="av-button"  onClick={() => props.itemClickHandler(ADMIN_SECTIONS.CLIENTS)}>View</div></div></div>
                <div className="cell small-4"><div className="gridcell"><div className="title">{ADMIN_SECTIONS.PRODUCTS}</div><div className="av-button" onClick={() => props.itemClickHandler(ADMIN_SECTIONS.PRODUCTS)}>View</div></div></div>
            </div>

        );
    }


}
export default AdminMenuGrid;
