export const SECTIONS = {
    CLIENTS : "clients",
    ADMIN : "admin",
    SETTINGS : "settings",
    NOTIFICATIONS : "notifications",
    CONTACT : "contact"
};

export const CLIENT_TABS = {
    OVERVIEW: "Overview",
    CLIENT_LIST: "Client & Venue List",
    VENUE_LIST: "Venue List",
    PRODUCTS: "Product Lines",
    SINGLE_VISIT: "Single Visit",
    TRENDS: "Trends",
    GROUP_TRENDS: "Group Trends",
    VISITS: "Visits",
    VENUE_DETAILS: "Venue Details"
};

export const ADMIN_SECTIONS = {
    HOME : "Admin Tools",
    USERS : "Dashboard Users",
    CLIENTS : "Clients Admin",
    FIX_SUGGESTIONS : "Fix Suggestions",
    APPROVALS : "Report Approvals",
    FEEDBACK : "Client Feedback",
    CSVS : "CSV Upload/Downloads",
    PRODUCTS: "Product List",
    PARTS: "Lead Brewer & Parts Admin",
    PARTS_REPORTS: "Parts Usage & Stock Reports"
};


export const WARRANTY_SECTIONS = {
    PARTS : "Parts",
    BREWERS : "Lead Brewers"
};



export const PART_REPORT_SECTIONS = {
    BREWER_USAGE : "Lead Brewer Part Usage",
    TECHNICIAN_STOCK : "Technician Stock Report"
};



export const BUTTON_MSG = {
    DELETE_USER : "Delete user message",
    EDIT_USER: "Edit user message",
    SAVE_USER: "Save user message",
    EDIT_CLIENT: "Edit client message",
    SAVE_CLIENT: "Save client message",
    ARCHIVE_CLIENT: "Archive client message",
    EDIT_VENUE: "Edit venue message",
    SAVE_VENUE: "Save venue message",
    ARCHIVE_VENUE: "Archive venue message",
    SAVE_REPORT: "Save report message",
    EDIT_REPORT: "Edit report message",
    PDF_REPORT: "View PDF of report"
};


export const FILTERS = {
    CLIENTS : "clients",
    PRODUCTS : "products",
    VENUES : "venues",
    TECHNICIANS : "technicians",
    DISTANCE : "distance",
    DATE : "date",
    DATETO : "dateTo",
    POSTCODE: "postcode",
    IS_ON: "isOn",
    RESTRICT: "isRestrict",
    EXCLUDE: "isExclude",
    LIST: "list",
    VALUE: "value",
    ADD: "add",
    REMOVE: "remove"
};
