import React from 'react'
import ClientsHeader from "./ClientsHeader";
import {ADMIN_SECTIONS, CLIENT_TABS, SECTIONS} from '../../resources/strings'
import ClientsBody from "./ClientsBody";
import ClientLevelHeader from './ClientLevelHeader'
import ClientLevelBody from "./ClientLevelBody";
import VenueLevelHeader from "./venues/VenueLevelHeader";
import VenueLevelBody from "./venues/VenueLevelBody";
import FaultsBody from "./faults/FaultsBody";
import FaultsHeader from "./faults/FaultsHeader";
import Axios from "axios";
import {API} from "../../api/APIClient";
import { useHistory } from 'react-router';


class OverviewLevel extends React.Component {

    constructor(props) {
        super(props);

        var forceClientSelection = false
        var selectedClient = undefined
        if (props.singleClient)
        {
            forceClientSelection = true
            selectedClient = props.singleClient
        }

        this.props.history.listen(location => {
            if (this.props.history.action === 'PUSH') {

            }
        })

        this.state = {
            currentOverviewTab: CLIENT_TABS.OVERVIEW,
            currentClientTab: CLIENT_TABS.OVERVIEW,
            currentVenueTab: CLIENT_TABS.SINGLE_VISIT,
            selectedClient: selectedClient,
            selectedVenue: undefined,
            forceClientSelection: forceClientSelection,


        };

    }

    didSelectClient(clientID, clientName) {
        //alert("PUSH clientID " + clientID)
        const encodedClient = Buffer.from(clientID + "_" + clientName).toString('base64');
        this.props.history.push('/clients/clid/' + encodedClient)
        this.setState({selectedClient: {id: clientID, name:clientName}})
    }

    didSelectVenue(venueID,venueName)
    {
        //alert("PUSH venueID " + venueID)
        const encodedVenue = Buffer.from(venueID + "_" + venueName).toString('base64');
        this.props.history.push('/clients/vid/'+encodedVenue)
        this.setState({selectedVenue: {id: venueID, name:venueName}})

    }

    componentDidMount() {
        this.props.sectionChangeHandler(SECTIONS.CLIENTS)
    }

    didSelectVenueWithClient(clientID, clientName, venueID, venueName) {
        console.log(clientID);
        console.log(clientName);
        console.log(venueID);
        console.log(venueName);
        this.setState({
            selectedClient: {id: clientID, name: clientName},
            selectedVenue: {id: venueID, name: venueName}
        })
    }

    didSelectFault(faultID)
    {
        this.setState({selectedFault: faultID})
        this.getFaultDetails(faultID)

    }

    componentWillReceiveProps(nextProps) {

        if (nextProps.match.params.clientID)
        {
            const encodedclient = nextProps.match.params.clientID
            let buff = new Buffer(encodedclient, 'base64');
            let client = buff.toString('ascii').split("_");
            let selectedClientID = client[0]
            let selectedClientName = client[1]

         //   alert("read clientID " + client)
            if (selectedClientID < 0){

                if (this.state.selectedClient !== undefined){
                    this.setState({currentOverviewTab: CLIENT_TABS.OVERVIEW, selectedClient: undefined, currentClientTab: CLIENT_TABS.OVERVIEW, selectedVenue: undefined, currentVenueTab: CLIENT_TABS.SINGLE_VISIT, selectedFault: undefined, faultDetails: undefined})
                }

            } else if (this.state.selectedClient === undefined || this.state.selectedClient.id != selectedClientID || this.state.selectedVenue !== undefined) {
                this.setState({selectedClient: {id: selectedClientID, name:selectedClientName}, selectedVenue: undefined,currentVenueTab: CLIENT_TABS.SINGLE_VISIT, selectedFault: undefined, faultDetails: undefined})
               // alert("SET" + client)
            }

        }
        if (nextProps.match.params.reportID && this.state.gotoReportID === undefined)
        {
            const encodedReportID = nextProps.match.params.reportID
            let buff = new Buffer(encodedReportID, 'base64');
            let reportID = buff.toString('ascii')
            this.setState({gotoReportID: reportID}, () => this.fetchIDsForGotoReport())

        }

        if (nextProps.match.params.venueID)
        {
            const encodedvenue = nextProps.match.params.venueID
            let buff = new Buffer(encodedvenue, 'base64');
            let venue = buff.toString('ascii').split("_");
            let selectedVenueID = venue[0]
            let selectedVenueName = venue[1]

         //   alert("read Venue " + venue)
            if (this.state.selectedVenue === undefined || this.state.selectedVenue.id != selectedVenueID ) {
                this.setState({selectedVenue: {id: selectedVenueID, name:selectedVenueName}, selectedFault: undefined, faultDetails: undefined})
              //  alert("SET" + venue)
            }

        }
        if (nextProps.singleClient)
        {
            this.setState({forceClientSelection: true, selectedClient: {id: nextProps.singleClient.id, name:nextProps.singleClient.name}})

        } else {
            this.setState({forceClientSelection: false})
            if (nextProps.reset === true)
            {
                this.props.history.push('/clients/clid/LTFfbm9uZQ==')
//                this.setState({currentOverviewTab: CLIENT_TABS.OVERVIEW, selectedClient: undefined, currentClientTab: CLIENT_TABS.OVERVIEW, selectedVenue: undefined, currentVenueTab: CLIENT_TABS.SINGLE_VISIT})
            }
        }

    }

    fetchIDsForGotoReport() {
        if (this.state.gotoReportID) {
            const formData = new FormData();
            formData.append('reportID', this.state.gotoReportID);

            Axios
                .post(API.visits.locClientInfo, formData)
                .then((res) => {

                    if (res.data.success) {

                        this.didSelectVenueWithClient(res.data.data.clientID, res.data.data.clientName, res.data.data.venueID, res.data.data.venueName)

                    }
                })
        }
    }

    resetGotoReportID() {
        this.setState({gotoReportID: undefined})
    }

    deSelectFault()
    {
        this.setState({selectedFault: undefined, faultDetails: undefined})

    }


    getFaultDetails(faultID){

        const formData = new FormData();
        formData.append('faultID', faultID);


        Axios
            .post(API.clients.getFaultDetails, formData)
            .then((res) => {

                if (res.data.success) {

                    this.setState({

                        faultDetails: res.data.data
                    })
                }
            })
    }

    backToClientsLevel()
    {
        this.props.history.push('/clients/clid/LTFfbm9uZQ==')
        this.setState({selectedClient: undefined, currentClientTab: CLIENT_TABS.OVERVIEW, selectedVenue: undefined, currentVenueTab: CLIENT_TABS.SINGLE_VISIT})
    }


    backToSingleClientLevel()
    {
        this.setState({selectedFault: undefined, selectedVenue: undefined ,currentVenueTab: CLIENT_TABS.SINGLE_VISIT}, )
    }


    handleOverviewTabClick (newTab)
    {
        this.setState({
            currentOverviewTab: newTab
        });
    }

    handleClientTabClick (newTab)
    {
        this.setState({
            currentClientTab: newTab
        });
    }


    handleVenueTabClick (newTab)
    {
        this.setState({
            currentVenueTab: newTab
        });
    }

    handleButtonMessage(msg) {
        this.setState({
            buttonMessage: msg
        });
    }

    render(){
        if (this.state.selectedFault === undefined) {

            if (this.state.selectedClient === undefined) {
                return(
                    <div className="main-wrap">
                        <ClientsHeader
                            currentTab={this.state.currentOverviewTab}
                            tabClickHandler={(tab) => this.handleOverviewTabClick(tab)}
                        />
                        <ClientsBody
                            currentTab={this.state.currentOverviewTab}
                            user={this.props.user}
                            didSelectClient={(clientID, clientName) => this.didSelectClient(clientID, clientName)}
                            didSelectVenue={(clientID, clientName, venueID, venueName) => this.didSelectVenueWithClient(clientID, clientName, venueID, venueName)}
                        />

                    </div>
                );
            } else {

                if (this.state.selectedVenue === undefined) {

                    return (
                        <div className="main-wrap">
                            <ClientLevelHeader
                                client={this.state.selectedClient}
                                currentTab={this.state.currentClientTab}
                                tabClickHandler={(tab) => this.handleClientTabClick(tab)}
                                backToTopClickHandler={(tab) => this.backToClientsLevel(tab)}
                                forceClientSelection={this.state.forceClientSelection}
                            />
                            <ClientLevelBody
                                client={this.state.selectedClient}
                                currentTab={this.state.currentClientTab}
                                user={this.props.user}
                                didSelectVenue={(venueID, venueName) => this.didSelectVenue(venueID, venueName)}
                            />
                        </div>);
                } else {

                    return (<div className="main-wrap">

                        <VenueLevelHeader
                            venue={this.state.selectedVenue}
                            user={this.props.user}
                            client={this.state.selectedClient}
                            currentTab={this.state.currentVenueTab}
                            backToTopClickHandler={(tab) => this.backToClientsLevel(tab)}
                            tabClickHandler={(tab) => this.handleVenueTabClick(tab)}
                            backClickHandler={(tab) => this.backToSingleClientLevel(tab)}
                            forceClientSelection={this.state.forceClientSelection}
                            buttonMessage={this.state.buttonMessage}
                            buttonMessageHandler={(msg) => this.handleButtonMessage(msg)}
                        />
                        <VenueLevelBody
                            venue={this.state.selectedVenue}
                            client={this.state.selectedClient}
                            currentTab={this.state.currentVenueTab}
                            gotoReportID={this.state.gotoReportID}
                            resetGotoReportID={() => this.resetGotoReportID()}
                            tabClickHandler={(tab) => this.handleVenueTabClick(tab)}
                            user={this.props.user}
                            didSelectFault={(faultID) => this.didSelectFault(faultID)}
                            didSelectVenue={(venueID, venueName) => this.didSelectVenue(venueID, venueName)}
                            buttonMessage={this.state.buttonMessage}
                            buttonMessageHandler={(msg) => this.handleButtonMessage(msg)}

                        />
                    </div>);
                }
            }

        } else {

                return(
                <div className="main-wrap">
                    <FaultsHeader
                        faultDetails = {this.state.faultDetails}
                        venue={this.state.selectedVenue}
                        client={this.state.selectedClient}
                        backToTopClickHandler={() => this.backToClientsLevel()}
                        forceClientSelection={this.state.forceClientSelection}
                        backClickHandler={() => this.backToSingleClientLevel()}
                        deSelectFault={() => this.deSelectFault()}/>
                    <FaultsBody
                        faultDetails = {this.state.faultDetails}
                        faultID={this.state.selectedFault}
                        user={this.props.user}
                    />

                </div>
            );
        }
    }



}

export default OverviewLevel;
