import React from "react";
import Axios from "axios";
import {API} from "../../api/APIClient";




class FeedbackDetail extends React.Component {


    constructor(props) {
        super(props);
        this.state = {
        };

    }


    componentDidMount()
    {
        this.getFeedback()
    }

    getFeedback() {
        const formData = new FormData();
        formData.append('id', this.props.itemToView);

        Axios
            .post(API.feedback.getFeedbackItem, formData)
            .then((res) => {

                if (res.data.success) {

                    if (res.data.data === false)
                    {
                        this.setState({
                            feedback: {}
                        })
                    } else {
                        this.setState({
                            feedback: res.data.data
                        })

                    }
                }
            })
    }





    render() {

        return (<div>

            {this.state.feedback === undefined ? <div>Loading...</div> :
            <div className="feedback-deets cell small-12">
                <div className="cell small-12">
                    <div className="title">Feedback Message</div>
                </div>
                <div className="cell small-4">
                    <span>Date sent:</span>
                    <div className="field">{this.state.feedback.sent}</div>
                </div>
                <div className="cell small-4">
                    <span>From:</span>
                    <div className="field">{this.state.feedback.fromName}</div>
                </div>
                <div className="cell small-4">
                    <span>Feedback Type:</span>
                    <div className="field">{this.state.feedback.messageType}</div>
                </div>

                <div className="cell small-12" >
                    <span>Message:</span>
                    <div className="field">{this.state.feedback.message}</div>
                </div>

                {this.state.feedback.client && this.state.feedback.client.clientname &&
                <div className="cell small-3">
                    <span>Client:</span>
                    <div className="field">{this.state.feedback.client.clientname}</div>
                </div>
                }
                {this.state.feedback.client && this.state.feedback.client.tsp_name &&
                <div className="cell small-3">
                    <span>Contact name:</span>
                    <div className="field">{this.state.feedback.client.tsp_name}</div>
                </div>
                }
                {this.state.feedback.client && this.state.feedback.client.tsp_phone &&
                <div className="cell small-3">
                    <span>Contact phone:</span>
                    <div className="field">{this.state.feedback.client.tsp_phone}</div>
                </div>
                }
                {this.state.feedback.client && this.state.feedback.client.tsp_email &&
                <div className="cell small-3">
                    <span>Contact email:</span>
                    <div className="field">{this.state.feedback.client.tsp_email}</div>
                </div>
                }


            </div>}

        </div>)
    }

}
export default FeedbackDetail;