import React from "react";
import {ADMIN_SECTIONS, BUTTON_MSG} from "../../../resources/strings";
import Axios from "axios";
import {API} from "../../../api/APIClient"
import Client from "../../clients/Client";

class UserDetails extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            userDetails: undefined
        };
        this.handleChange = this.handleChange.bind(this);
    }


    componentDidMount() {


        this.getAllVenues()

        if (this.props.userID === -1) {
            this.setState({
                userDetails: {firstname:""},
                edit_clients: [],
                edit_firstname: "",
                edit_surname: "",
                edit_emailaddress: "",
                edit_note: "",
                edit_admin: "",
                edit_locs: []
            })
        } else {
            this.getUserDetails()

        }

    }

    componentWillUnmount() {

        if(this.props.buttonMessage === BUTTON_MSG.EDIT_USER) {
            if (window.confirm("Editing in progress - do you want to save before you leave?")) {
                this.saveUser()
            }
        }

    }


    componentWillReceiveProps(nextProps) {

        if (this.props.userID === -1 && nextProps.buttonMessage === undefined)
        {
            this.props.itemClickHandler(ADMIN_SECTIONS.USERS) //go back
        }
        if(nextProps.buttonMessage === BUTTON_MSG.SAVE_USER){
            this.saveUser()
        }
        if(nextProps.buttonMessage === BUTTON_MSG.EDIT_USER && this.props.buttonMessage !== BUTTON_MSG.SAVE_USER){

            var tickedLocs = this.pullOutLocsFromClients(this.state.clients)

            var editAdmin = "0";
            if (parseInt(this.state.userDetails.technician)  === 1){
                if (parseInt(this.state.userDetails.admin)  === 1 ){
                    editAdmin = "3";
                } else {
                    editAdmin = "2";

                }
            } else {
                editAdmin = this.state.userDetails.admin
            }
            this.setState({
                edit_firstname: this.state.userDetails.firstname,
                edit_surname: this.state.userDetails.surname,
                edit_emailaddress: this.state.userDetails.email,
                edit_note: this.state.userDetails.note,
                edit_admin: editAdmin,
                edit_clients: this.state.clients,
                edit_locs: tickedLocs
            })
        }
    }

    pullOutLocsFromClients(clients)
    {
        var tickedLocs = []
        clients.map((client, c) => {

            return client.locations.map((loc, d) =>
            {
                tickedLocs.push(loc.id)
                return 1
            })

        })
        return tickedLocs
    }


    handleChange(event) {

        const target = event.target;
        const value = target.value;
        const name = target.name;
        this.setState({
            [name]: value
        });


    }


    saveUser() {

        if (this.state.edit_firstname && this.state.edit_surname && this.state.edit_emailaddress) {
            const formData = new FormData();
            formData.append('userID', this.props.userID);
            formData.append('firstname', this.state.edit_firstname);
            formData.append('surname', this.state.edit_surname);
            formData.append('emailaddress', this.state.edit_emailaddress);
            formData.append('note', this.state.edit_note);
            formData.append('admin', this.state.edit_admin);
            formData.append('locations', this.state.edit_locs);

            Axios
                .post(API.users.editUser, formData)
                .then((res) => {

                    if (res.data.success) {

                        this.props.buttonMessageHandler(undefined);

                        if (this.props.userID === -1 && res.data.result){
                            this.props.didSelectUser(res.data.result)
                        } else {
                            this.getUserDetails()
                        }
                    } else {
                        var msg = "Error saving user. "
                        if (res.data.error) {

                            msg = msg + res.data.error

                        }
                        alert(msg)
                        this.props.buttonMessageHandler(BUTTON_MSG.EDIT_USER);

                    }
                }).catch(error => {

                var msg = "Error saving user. "

                if (error.response) {
                    if (error.response.data.error) {

                        msg = msg + error.response.data.error

                    }

                    alert(msg)
                } else {
                    alert(error)

                }
            })
        } else {
            alert("Please fill in the first name, last name and email fields.")
            this.props.buttonMessageHandler(BUTTON_MSG.EDIT_USER);
        }


    }


    getUserDetails()
    {
        this.getClientsVenues();

        const formData = new FormData();
        formData.append('userID', this.props.userID);

        Axios
            .post(API.users.getUser, formData)
            .then((res) => {

                if (res.data.success) {

                    if (res.data.data === false)
                    {
                        this.props.itemClickHandler(ADMIN_SECTIONS.USERS) //no user...just go back?

                    } else {
                        this.setState({
                            userDetails: res.data.data
                        })
                    }
                }
            })
    }

    cancelDelete()
    {
        this.props.buttonMessageHandler(undefined);
    }

    deleteUser(){
        this.props.buttonMessageHandler(undefined);

        const formData = new FormData();
        formData.append('userID', this.props.userID);

        Axios
            .post(API.users.deleteUser, formData)
            .then((res) => {
                if (res.data.success) {


                        this.props.itemClickHandler(ADMIN_SECTIONS.USERS)

                } else {
                    var msg = "Error deleting user. "
                    alert(msg)

                }
            }).catch(error => {

            var msg = "Error deleting user. "

            if (error.response)
            {
                if (error.response.data.error) {

                    msg = msg + error.response.data.error

                }

                alert(msg)
            }else{
                alert(error)

            }

        })


    }


    sendPassReset() {

        const formData = new FormData();
        formData.append('email', this.state.userDetails.email);
        Axios
            .post(API.auth.resetPassword, formData)
            .then((res) => {


                if (res.data.success) {

                    alert("An email has been sent to " + this.state.userDetails.email + " with further instructions.")

                }

            }).catch(error => {



            console.log(error.response)
            if (error.response.data.error) {

                alert(error.response.data.error)
            } else{
                alert("Could not send request.  Please check connection and try again.")

            }
        });

    }

    getClientsVenues() {
        const formData = new FormData();
        formData.append('userID', this.props.userID);

        Axios
            .post(API.locations.clientsAndVenuesForUser, formData)
            .then((res) => {

                if (res.data.success) {

                    this.setState({
                        clients: res.data.data
                    })
                }
            })
    }


    getAllVenues() {
        const formData = new FormData();

        Axios
            .post(API.locations.allClientsAndVenues, formData)
            .then((res) => {

                if (res.data.success) {

                    this.setState({
                        allClients: res.data.data
                    })
                }
            })
    }


    addVenueClicked(clientKey, venueKey) {
        let clientToAdd = JSON.parse(JSON.stringify(this.state.allClients[clientKey]))
        let locToAdd = clientToAdd.locations[venueKey]

        var clientIDX=-1
        this.state.edit_clients.map((client, c) => {
            if (client.id === clientToAdd.id){
                clientIDX = c
            }

            return 0
        })

        if (clientIDX >= 0) {

            let updatedClients = this.state.edit_clients.slice(0)
            let updatedLocs = updatedClients[clientIDX].locations.slice(0)
            updatedLocs.push(locToAdd)
            updatedClients[clientIDX].locations = updatedLocs
            this.setState({
                edit_clients: updatedClients,
                edit_locs: this.pullOutLocsFromClients(updatedClients)
            })

        } else {
            let updatedClients = this.state.edit_clients.slice(0)
            let updatedLocs = []
            updatedLocs.push(locToAdd)
            clientToAdd.locations = updatedLocs
            updatedClients.push(clientToAdd)
            this.setState({
                edit_clients: updatedClients,
                edit_locs: this.pullOutLocsFromClients(updatedClients)
            })
        }
        alert("Venue added successfully")

    }

    AddAllVenuesClicked(clientKey) {
        let clientToAdd = JSON.parse(JSON.stringify(this.state.allClients[clientKey]))
        var clientIDX=-1
        this.state.edit_clients.map((client, c) => {
            if (client.id === clientToAdd.id){
                clientIDX = c
            }

            return 0
        })

        if (clientIDX >= 0) {

            let updatedClients = this.state.edit_clients.slice(0)
            let updatedLocs = clientToAdd.locations.slice(0)
            updatedClients[clientIDX].locations = updatedLocs
            this.setState({
                edit_clients: updatedClients,
                edit_locs: this.pullOutLocsFromClients(updatedClients)
            })

        } else {
            let updatedClients = this.state.edit_clients.slice(0)
            let updatedLocs = clientToAdd.locations.slice(0)
            clientToAdd.locations = updatedLocs
            updatedClients.push(clientToAdd)
            this.setState({
                edit_clients: updatedClients,
                edit_locs: this.pullOutLocsFromClients(updatedClients)
            })
        }
        alert("Venues added successfully")

    }


    deleteVenueByIDClicked(clientKey, venueKey) {

        let clientToDelete = this.state.allClients[clientKey]
        let locToDelete = clientToDelete.locations[venueKey]

        var clientIDX=-1
        this.state.edit_clients.map((client, c) => {
            if (client.id === clientToDelete.id){
                clientIDX = c
            }
            return 0
        })

        if (clientIDX >= 0) {
            let updatedClients = this.state.edit_clients.slice(0)
            let updatedLocs = updatedClients[clientIDX].locations.slice(0)

            if (updatedLocs.length === 1)//just remove the client
            {
                updatedClients.splice(clientIDX,1)
                this.setState({
                    edit_clients: updatedClients,
                    edit_locs: this.pullOutLocsFromClients(updatedClients)
                })

            } else {
                var locIDX=-1

                updatedLocs.map((loc, c) => {
                    if (loc.id === locToDelete.id){
                        locIDX = c
                    }
                    return 0
                })
                if (locIDX >= 0) {
                    updatedLocs.splice(locIDX,1)
                    updatedClients[clientIDX].locations = updatedLocs
                    this.setState({
                        edit_clients: updatedClients,
                        edit_locs: this.pullOutLocsFromClients(updatedClients)
                    })
                }
            }

        }
        alert("Venue removed successfully")


    }


    deleteAllVenuesClicked(clientKey) {

        let clientToDelete = this.state.allClients[clientKey]

        var clientIDX=-1
        this.state.edit_clients.map((client, c) => {
            if (client.id === clientToDelete.id){
                clientIDX = c
            }
            return 0
        })

        if (clientIDX >= 0) {
            let updatedClients = this.state.edit_clients.slice(0)

            updatedClients.splice(clientIDX,1)
            this.setState({
                    edit_clients: updatedClients,
                    edit_locs: this.pullOutLocsFromClients(updatedClients)
            })
        }

        alert("Venues removed successfully")
    }



    deleteVenueClicked(clientKey, venueKey) {

        let updatedClients = this.state.edit_clients.slice(0)
        let updatedLocs = updatedClients[clientKey].locations.slice(0)
        updatedLocs.splice(venueKey,1)
        if (updatedLocs.length === 0)
        {
            updatedClients.splice(clientKey,1)

        } else {
            updatedClients[clientKey].locations = updatedLocs
        }
        this.setState({
            edit_clients: updatedClients,
            edit_locs: this.pullOutLocsFromClients(updatedClients)
        })

    }



    render() {

        return(<div>
            {this.state.userDetails === undefined ?

            <div>Loading...</div>

                :
                <div className="cell small-12 user_deets details-wrap small-pad">



                    <div className="deets_sect">
                        <div className="cell small-12 small-pad">
                            <div className="title">Main Details</div>
                        </div>

                        <div className="cell small-6 small-pad">
                            <div className="deet">
                                <span>First Name</span>
                                {this.props.buttonMessage === BUTTON_MSG.EDIT_USER ?
                                    <input className="edit-field" type="text" name="edit_firstname" placeholder="First Name" value={this.state.edit_firstname} onChange={this.handleChange}/>
                                :
                                    <div className="field">{this.state.userDetails.firstname}</div>

                                }
                            </div>
                        </div>
                        <div className="cell small-6 small-pad">
                            <div className="deet">
                                <span>Last Name</span>
                                {this.props.buttonMessage === BUTTON_MSG.EDIT_USER ?
                                    <input className="edit-field" type="text" name="edit_surname" placeholder="Surname" value={this.state.edit_surname} onChange={this.handleChange}/>
                                    :
                                    <div className="field">{this.state.userDetails.surname}</div>

                                }
                            </div>
                        </div>
                        <div className="cell small-12 small-pad">
                            <div className="deet">
                                <span>Email</span>
                                {this.props.buttonMessage === BUTTON_MSG.EDIT_USER ?
                                    <input className="edit-field" type="text" name="edit_emailaddress" placeholder="Email Address" value={this.state.edit_emailaddress} onChange={this.handleChange}/>
                                    :
                                    <div className="field">{this.state.userDetails.email}</div>

                                }
                            </div>
                        </div>
                        <div className="cell small-12 small-pad">
                            <div className="deet">
                                <span>Welcome Message</span>
                                {this.props.buttonMessage === BUTTON_MSG.EDIT_USER ?
                                    <input className="edit-field" type="text" name="edit_note" placeholder="Welcome Message" value={this.state.edit_note} onChange={this.handleChange}/>
                                    :
                                    <div className="field">{this.state.userDetails.note}</div>

                                }
                            </div>
                        </div>
            
                        <div className="clearfix" />

                    </div>

                    <div className="deets_sect">

                        <div className="cell small-12 small-pad">
                            <div className="title">User Type</div>
                        </div>

                        <div className="cell small-6 small-pad">
                            <div className="deet">
                                <span>User Type</span>
                                {this.props.buttonMessage === BUTTON_MSG.EDIT_USER ?

                                    <select className="edit-field" value={this.state.edit_admin} name="edit_admin" onChange={this.handleChange}>
                                        <option value="0">Client User</option>
                                        <option value="1">Administrator</option>
                                        <option value="2">Technician</option>
                                        <option value="3">Admin Technician</option>
                                    </select>
                                    :
                                    <div className="field">{ parseInt(this.state.userDetails.technician)  === 1 ? <div>{parseInt(this.state.userDetails.admin)  === 1 ? "Admin Technician" : "Technician"}</div>
                                        :
                                        <div>{parseInt(this.state.userDetails.admin)  === 1 ? "Administrator" : "Client User"}</div>


                                    }</div>
                                }
                            </div>
                        </div>

                        <div className="clearfix" />

                    </div>

                    {(this.props.userID !== -1) &&

                        <div className="deets_sect">

                            <div className="cell small-12 small-pad">
                                <div className="title">Password Reset</div>
                            </div>

                            <div className="cell small-6 small-pad">
                                <div className="deet">
                                <div className="av-button" onClick={() => this.sendPassReset()}>Send Password Reset Email</div>
                                </div>
                            </div>
                            <div className="clearfix"></div>
                        </div>}






                    {this.props.buttonMessage === BUTTON_MSG.EDIT_USER ?
                        <div className="deets_sect">

                            <div className="cell small-12 small-pad">
                                <div className="title">Select this users Associated Clients &amp; Venues</div>
                            </div>

                            <div className="cell small-12 deets-list">
                                <div className="cell small-12 small-pad">
                                    <div className="title">This users Selected Clients &amp; Venues</div>
                                    <span className="title-note">Pick clients or venues from this list to add to the users list</span>
                                </div>
                                {this.state.edit_clients ?
                                    this.state.edit_clients.map((client, c) => {

                                        return <Client selectMode={false} deleteMode={true} deleteClicked={(clientKey, venueKey) => this.deleteVenueClicked(clientKey, venueKey)}
                                                       idx={c} key={c} clientID={client.id} userid={this.props.userID}
                                                       name={client.name} venues={client.locations} locCount={client.locCount}
                                                       didSelectClient={this.props.didSelectClient}
                                        />;
                                    })
                                    :

                                    <div>Loading...</div>}

                            </div>
                            <div className="cell small-12 deets-list dark-list">
                                <div className="cell small-12 small-pad">
                                    <div className="title">Entire System Client &amp; Venue List</div>
                                    <span className="title-note">Pick clients or venues from this list to add to the users list</span>
                                </div>

                                {this.state.allClients ?
                                    this.state.allClients.map((client, c) => {

                                        return <Client selectMode={false} tickMode={true} tickedLocs={this.state.edit_locs} key={c} clientID={client.id} userid={this.props.userID}
                                                       name={client.name} venues={client.locations}
                                                       didSelectClient={this.props.didSelectClient}
                                                       idx={c} locCount={client.locCount}
                                                       deleteAllClicked={(clientKey) => this.deleteAllVenuesClicked(clientKey)}
                                                       addAllClicked={(clientKey) => this.AddAllVenuesClicked(clientKey)}
                                                       deleteClicked={(clientKey, venueKey) => this.deleteVenueByIDClicked(clientKey, venueKey)}
                                                       addClicked={(clientKey, venueKey) => this.addVenueClicked(clientKey, venueKey)}
                                        />;
                                    })
                                    :

                                    <div>Loading...</div>}
                            </div>

                            <div className="clearfix" />

                        </div>
                        :







                    <div className="deets_sect">

                        <div className="cell small-12 small-pad">
                            <div className="title">Associated Clients &amp; Venues</div>
                        </div>

                        {this.state.clients ?
                            this.state.clients.map((client, c) => {

                                    return <Client selectMode={false}  key={c} clientID={client.id} userid={this.props.userID}
                                                   name={client.name} venues={client.locations} locCount={client.locCount}
                                                   didSelectClient={this.props.didSelectClient}
                                    />;
                                })
                            :

                            <div>Loading...</div>}

                        <div className="clearfix" />
                    </div>}
                </div>

            }
            {( this.props.buttonMessage === BUTTON_MSG.DELETE_USER ) && <div className="modal">
                <div className="modal-content"><div className="cell small-12">Are you sure you want to delete this user? This can not be undone!</div>
                    <div className="cell small-12"/>

                    <div className="av-button" onClick={() => this.deleteUser()}>OK</div>
                <div className="av-button" onClick={() => this.cancelDelete()}>Cancel</div>
                </div></div>}
        </div>)
    }
}
export default UserDetails;

