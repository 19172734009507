import React from "react";
import Axios from "axios";
import {API} from "../../../api/APIClient";


class TechnicalIssues extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            issues : props.issues
        }
        this.handleChange = this.handleChange.bind(this);
        this.imageInput = React.createRef();
        this.videoInput = React.createRef();
        this.uploadImage = this.uploadImage.bind(this);
        this.uploadVideo = this.uploadVideo.bind(this);

    }



    componentDidMount() {
        this.getImages()
        this.getVideos()
    }

    handleChange(event) {

        const target = event.target
        const value = target.value
        const namearr = target.name.split("_");
        if (namearr.length !== 2) {
            return;
        }
        const name = namearr[0];
        const index = namearr[1];

        var issues = this.state.issues
        var row = issues[index]
        row[name] = value
        issues[index] = row
        this.setState({
            issues: issues,
            saved: false
        });
    }
    deleteVideo(row) {
        if (window.confirm("Are you sure you want to delete this video? This can not be undone!")) {
            var videos = this.state.videos
            var vidrow = videos[row]

            const formData = new FormData();
            formData.append('issueID', vidrow.issue_id);
            formData.append('videoID', vidrow.id);
            formData.append('reportID', this.props.visitID);

            Axios
                .post(API.visits.deleteVisitVideo, formData)
                .then((res) => {

                    if (res.data.success) {

                        this.setState({
                            videos: res.data.videos
                        })


                    } else {
                        var msg = "Error deleting video, please check your connection and try again."
                        alert(msg)

                    }
                }).catch(error => {

                var msg = "Error deleting video. "
                this.setState({
                    saving: false
                })
                if (error.response) {
                    if (error.response.data.error) {
                        msg = msg + error.response.data.error
                    }
                    alert(msg)
                } else {
                    alert(error)

                }
            })
        }
    }


    deleteImage(row) {
        if (window.confirm("Are you sure you want to delete this image? This can not be undone!")) {
            var images = this.state.images
            var imgrow = images[row]

            const formData = new FormData();
            formData.append('issueID', imgrow.issue_id);
            formData.append('imageID', imgrow.id);
            formData.append('reportID', this.props.visitID);

            Axios
                .post(API.visits.deleteVisitImage, formData)
                .then((res) => {

                    if (res.data.success) {

                        this.setState({
                            images: res.data.images
                        })


                    } else {
                        var msg = "Error deleting image, please check your connection and try again."
                        alert(msg)

                    }
                }).catch(error => {

                var msg = "Error deleting image. "
                this.setState({
                    saving: false
                })
                if (error.response) {
                    if (error.response.data.error) {
                        msg = msg + error.response.data.error
                    }
                    alert(msg)
                } else {
                    alert(error)

                }
            })
        }
    }


    deleteItem(row) {

        if (window.confirm("Are you sure you want to delete this fault? This can not be undone!")) {
            var issues = this.state.issues
            var issuerow = issues[row]

            const formData = new FormData();
            formData.append('reportID', this.props.visitID);
            formData.append('issueID', issuerow.id);

            Axios
                .post(API.visits.deleteTechIssue, formData)
                .then((res) => {

                    if (res.data.success) {

                        this.setState({
                            issues: res.data.issues
                        })


                    } else {
                        var msg = "Error deleting Fault, please check your connection and try again."
                        alert(msg)
                        this.setState({
                            saving: false
                        })

                    }
                }).catch(error => {

                var msg = "Error deleting Fault. "
                this.setState({
                    saving: false
                })
                if (error.response) {
                    if (error.response.data.error) {
                        msg = msg + error.response.data.error
                    }
                    alert(msg)
                } else {
                    alert(error)

                }
            })
        }

    }

    uploadVideo(event){
        if (event.target.files.length > 0) {

            var uploadVideo = event.target.files[0];

            if (uploadVideo != null) {

                this.setState({
                    isUploading : true
                });
                var formData = new FormData();
                formData.append("video", uploadVideo);
                formData.append("faultID", this.state.videoFaultID);
                formData.append('reportID', this.props.visitID);

                var context = this;

                Axios.post(API.visits.uploadVideo, formData, {
                    onUploadProgress : function(data) {
                        if (data.lengthComputable) {
                            var percent = Math.floor(((data.loaded / data.total) * 100));

                            console.log("UPLOAD: " + percent + "%");
                            context.setState({
                                videoProgressPercent : percent
                            });
                        }
                    }
                })
                    .then((res) => {

                        if (res.data.success) {

                            this.setState({
                                videos: res.data.videos,
                                videoProgressPercent : undefined
                            })


                        } else {
                            var msg = "Error adding videos, please check your connection and try again."
                            if (res.data.error) {
                                msg = res.data.error.replace(/<\/?[^>]+(>|$)/g, "");
                            }
                            alert(msg)
                            this.setState({
                                saving: false
                            })

                        }
                    })
                    .catch(function(err) {
                        console.log(err);
                        alert("Error", "An unknown error has occurred. Please try again later. [1171]")
                        context.setState({
                            isUploading : false
                        });
                    });
            }

        }
    }


    uploadImage(event){
        if (event.target.files.length > 0) {
            var uploadImage = event.target.files[0];

            if (uploadImage != null) {
                this.setState({
                    isUploading : true
                });
                var formData = new FormData();
                formData.append("image", uploadImage);
                formData.append("faultID", this.state.imageFaultID);
                formData.append('reportID', this.props.visitID);

                var context = this;

                Axios.post(API.visits.uploadImage, formData, {
                    onUploadProgress : function(data) {
                        if (data.lengthComputable) {
                            var percent = ((data.loaded / data.total) * 100);

                            console.log("UPLOAD: " + percent + "%");
                            context.setState({
                                uploadProgressPercent : percent
                            });
                        }
                    }
                })
                    .then((res) => {

                        if (res.data.success) {

                            this.setState({
                                images: res.data.images
                            })


                        } else {
                            var msg = "Error adding image, please check your connection and try again."
                            if (res.data.error) {
                                msg = res.data.error.replace(/<\/?[^>]+(>|$)/g, "");
                            }
                            alert(msg)
                            this.setState({
                                saving: false
                            })

                        }
                    })
                    .catch(function(err) {
                        console.log(err);
                        alert("Error", "An unknown error has occurred. Please try again later. [1170]")
                        context.setState({
                            isUploading : false
                        });
                    });
            }

        }
    }

    addItem() {
        const formData = new FormData();
        formData.append('reportID', this.props.visitID);

        Axios
            .post(API.visits.addTechIssue, formData)
            .then((res) => {

                if (res.data.success) {

                    this.setState({
                        issues: res.data.issues
                    })


                } else {
                    var msg = "Error adding Fault, please check your connection and try again."
                    alert(msg)
                    this.setState({
                        saving: false
                    })

                }
            }).catch(error => {

            var msg = "Error adding issue. "
            this.setState({
                saving: false
            })
            if (error.response) {
                if (error.response.data.error) {
                    msg = msg + error.response.data.error
                }
                alert(msg)
            } else {
                alert(error)

            }
        })
    }


    getImages() {
        const formData = new FormData();
        formData.append('reportID', this.props.visitID);

        Axios
            .post(API.visits.getImages, formData)
            .then((res) => {

                if (res.data.success) {

                    this.setState({
                        images: res.data.images
                    })


                } else {
                    var msg = "Error getting images"
                    alert(msg)

                }
            }).catch(error => {

            var msg = "Error getting images. "
            this.setState({
                saving: false
            })
            if (error.response) {
                if (error.response.data.error) {
                    msg = msg + error.response.data.error
                }
                alert(msg)
            } else {
                alert(error)

            }
        })
    }


    getVideos() {
        const formData = new FormData();
        formData.append('reportID', this.props.visitID);

        Axios
            .post(API.visits.getVideos, formData)
            .then((res) => {

                if (res.data.success) {

                    this.setState({
                        videos: res.data.videos
                    })


                } else {
                    var msg = "Error getting videos"
                    alert(msg)

                }
            }).catch(error => {

            var msg = "Error getting videos. "
            this.setState({
                saving: false
            })
            if (error.response) {
                if (error.response.data.error) {
                    msg = msg + error.response.data.error
                }
                alert(msg)
            } else {
                alert(error)

            }
        })
    }



    saveChanges() {
        if (this.state.issues) {
            const formData = new FormData();
            formData.append('reportID', this.props.visitID);
            formData.append('issues', JSON.stringify(this.state.issues));

            Axios
                .post(API.visits.editTechIssues, formData)
                .then((res) => {

                    if (res.data.success) {

                        this.setState({
                            saving: false,
                            saved: true
                        })


                    } else {
                        var msg = "Error saving Faults, please check the details entered and try again."
                        alert(msg)
                        this.setState({
                            saving: false
                        })

                    }
                }).catch(error => {

                var msg = "Error saving issues. "
                this.setState({
                    saving: false
                })
                if (error.response) {
                    if (error.response.data.error) {
                        msg = msg + error.response.data.error
                    }
                    alert(msg)
                } else {
                    alert(error)

                }
            })
        }

    }

    showAddImage(faultID) {

        var imgCount = 0
        if (this.state.images){

            this.state.images.map((imgrow, i) => {

                if (imgrow.issue_id === faultID) {
                    imgCount++
                }
                return <div></div>
            })

        }


        if (imgCount < 4) {
            return true
        }
        return false

    }


    showAddVideo(faultID) {

        var vidCount = 0
        if (this.state.videos){

            this.state.videos.map((vidrow, i) => {

                if (vidrow.issue_id === faultID) {
                    vidCount++
                }
                return <div></div>
            })

        }


        if (vidCount < 4) {
            return true
        }
        return false

    }

    render() {

        return (<div style={{textAlign: "center"}}>

            <input type={"file"} style={{visibility:"hidden"}}  ref={this.imageInput} onChange={this.uploadImage } />
            <input type={"file"} style={{visibility:"hidden"}}  ref={this.videoInput} onChange={this.uploadVideo } />

            <div style={{float: "right"}} className="av-button" onClick={() => this.addItem()}> + Add Fault</div>

            <table cellPadding={6} style={{width: "100%"}}><tr bgcolor="#23A6A6" style={{color:"#ffffff", fontWeight : "bold"}}><td></td><td>Fault Desctiption</td>
                <td>Priority</td>
                <td>Logged</td>
                <td>Resolved</td>
                <td>Recurring</td>
                <td>Images (tap to delete)</td>
                <td>Videos (tap to delete)</td>
                <td></td>
            </tr>
                {this.state.issues && this.state.issues.map((row, c) => {

                    return <tr bgcolor="#dedede">
                        <td>{c+1}</td>

                        <td><textarea rows={5} value={row.issue} onChange={this.handleChange} name={"issue_"+c}  lines className="edit-field" /></td>
                        <td width={75}><select className="edit-field" value={row.urgency} name={"urgency_"+c} onChange={this.handleChange}>
                            <option value="Low">Low</option>
                            <option value="Standard">Standard</option>
                            <option value="High">High</option>
                        </select></td>

                        <td width={75}><select className="edit-field" value={row.logged} name={"logged_"+c} onChange={this.handleChange}>
                            <option value="yes">Yes</option>
                            <option value="no">No</option>
                        </select></td>

                        <td width={75}><select className="edit-field" value={row.actioned} name={"actioned_"+c} onChange={this.handleChange}>
                            <option value="yes">Yes</option>
                            <option value="no">No</option>
                        </select></td>

                        <td  width={75}><select className="edit-field" value={row.recurring} name={"recurring_"+c} onChange={this.handleChange}>
                            <option value="1">Yes</option>
                            <option value="0">No</option>
                        </select></td>

                        <td>

                            {this.state.images && this.state.images.map((imgrow, i) => {

                                if (imgrow.issue_id === row.id) {
                                    return <img alt={"delete"} onClick={() => this.deleteImage(i)} className="uploadImg" src={imgrow.image} style={{marginRight: "5px",width : "70px"}} />
                                } else {
                                    return <div></div>
                                }
                            })}

                            {this.showAddImage(row.id) &&
                            <div style={{height: "100%"}}><div className={"av-button"}
                                                               onClick={() => this.setState({imageFaultID: row.id}, this.imageInput.current.click())}>+Add Image</div></div>
                            }

                        </td>
                        <td>

                            {this.state.videos && this.state.videos.map((vidrow, i) => {

                                if (vidrow.issue_id === row.id) {
                                    return <video width="99%" onClick={() => this.deleteVideo(i)} autoPlay={false} style={{marginRight: "5px",width : "70px"}} muted={false} controls={true} src={vidrow.video}></video>
                                } else {
                                    return <div></div>
                                }
                            })}

                            {this.showAddVideo(row.id) &&
                            <div style={{height: "100%"}}><div className={"av-button"}
                                                               onClick={() => this.setState({videoFaultID: row.id}, this.videoInput.current.click())}>+Add Video</div></div>
                            }
                            {this.state.videoProgressPercent && (this.state.videoFaultID == row.id) &&
                            <div>Uploading {this.state.videoProgressPercent}%</div>}

                        </td>
                        <td width={35}>
                            <div  style={{width: "35px", height: "35px"}} className="delete" onClick={() => this.deleteItem(c)}></div>
                        </td>
                    </tr>

                })}

            </table>

            {this.state.saving
                ?
                <div className="av-button">Saving...</div>
                :
                <div className="av-button" onClick={() => this.setState({saving: true,saved: false}, this.saveChanges())}>Save Faults</div>
            }
            {this.state.saved && <div>Changes to faults saved successfully</div>}

        </div>);
    }

}
export default TechnicalIssues
