import React from "react";
import Axios from "axios";
import {API} from "../../api/APIClient";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import Paper from "@material-ui/core/Paper/Paper";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TablePagination from "@material-ui/core/TablePagination";
import MyPie from "../common/MyPie";




class LatestClientResults extends React.Component {

    constructor(props) {
        super(props);


        this.state = {
            page: 0,
            rowsPerPage: 25,
            expanded: false,
            numVenues: null
        };
    }

    componentDidUpdate(prevProps) {
        if (prevProps.filters !== this.props.filters) {
            this.getClientData();
        }
    }

    onShowDetails = () => {
        if (typeof this.props.onShowDetails === 'function') {
            this.props.onShowDetails();
        }
    }

    expandOrContract()
    {
        let newExpanded = !this.state.expanded;
        this.setState({expanded : newExpanded});
        if (newExpanded && this.state.summaryPieData === undefined) {
            this.getClientData();
        }
    }

    componentDidMount() {
        // if (this.props.index <= 10) {
        //     this.expandOrContract()
        // }
    }

    getClientData() {

        const formData = new FormData();
        formData.append('clientID', this.props.clientID);
        formData.append('userID', this.props.userid);

        let filters = Object.assign({},this.props.filters);
        filters.clients = {isOn: true, isRestrict: true, isExclude: false, list: [{id: this.props.clientID}]}
        formData.append('filters', JSON.stringify(filters));

        Axios
            .post(API.clients.getSummaryData2, formData)
            .then((res) => {

                if (res.data.success) {

                    var sumTble = res.data.summaryData.chart.map((row, c) => {

                        return <TableRow key={c}>
                            <TableCell component="th" scope="row">{row.title}<span className={"dot_" + row.title}></span></TableCell>
                            <TableCell><span className={"dot_" + row.value} />{row.value}</TableCell>
                            <TableCell>{row.percentage}%</TableCell>
                        </TableRow>

                    })
                    sumTble.push(<TableRow key="totals">
                        <TableCell component="th" scope="row">Total</TableCell>
                        <TableCell>{res.data.summaryData.total}</TableCell>
                        <TableCell>100%</TableCell>
                    </TableRow>)

                    this.setState({
                        numVenues: res.data.summaryData.numVenues,
                        summaryPieData: res.data.summaryData.chart.map((segment, c) => {

                            var parsedVal = parseFloat(segment.percentage, 10);
                            if (isNaN(parsedVal)) {
                                parsedVal = 0
                            }

                            return {title: segment.title, value: parsedVal, color: segment.color};

                        }),
                        summaryTableData:sumTble
                    })
                }
            })
    }

    render() {

        return(


            <div className="cell small-12 filters over-latest" style={{marginBottom: "12px"}}>

                    <div className="l-grey-arrow" onClick={() => this.expandOrContract()}>{this.state.expanded ? <span className='innerarrow down'></span> : <span className='innerarrow'></span>}</div>

                <div className="title" onClick={() => this.expandOrContract()}><span style={{textDecoration: "underline", cursor: "pointer"}} onClick={() => this.onShowDetails()}>{this.props.name}</span>{this.state.expanded && <span> {this.state.numVenues ? "- Total from " + this.state.numVenues + (this.state.numVenues === 1 ? " venue" : " venues") : "" }</span>}</div>

                    {this.state.expanded &&

                        <div>
                <div className="cell small-12 small-pad"/>

                <div className="cell small-7 small-pad">

                    <TableContainer component={Paper}>
                        <Table  size="small" aria-label="clean results">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Score</TableCell>
                                    <TableCell>Number Of Lines By Result</TableCell>
                                    <TableCell>Total Percentage</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {this.state.summaryTableData === undefined ? <TableRow><TableCell>Loading...</TableCell></TableRow> : this.state.summaryTableData.slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage)}
                            </TableBody>
                        </Table>

                    </TableContainer>

                </div>
                            {/* <div className="cell small-1 small-pad"/> removed but may need */}

                            <div className="cell small-5 small-pad small-pie-wrap">
                    {this.state.summaryPieData === undefined ? <div>loading...</div> :
                        <div className="title pie-view" style={{textAlign: "center"}}>

                            <MyPie data={this.state.summaryPieData} />
                        </div>
                    }

                </div>
                            {/* <div className="cell small-1 small-pad"/> removed but may need */}

                        </div>}

            </div>
        );

    }
}

export default LatestClientResults;
