import React from 'react'
import Axios from "axios";
import {API} from "../../api/APIClient";



class ChangePass extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            submitting: false,
            newpass: "",
            newpass2: "",
            oldPass: ""
        };
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(event) {

        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });


    }



    confirmClicked()
    {

        if (this.state.submitting)
        {
            return;
        }

        var newPassToSend = this.state.newpass2.trim()
        if (this.state.newpass.length > 0 && newPassToSend.length > 5 && this.state.oldPass.length > 0)
        {

            if (this.state.newpass ===  this.state.newpass2) {


                this.setState({
                    submitting:true,
                    submitMessage:"Sending request..."
                });
                const formData = new FormData();
                formData.append('newpassword', newPassToSend);
                formData.append('password', this.state.oldPass);

                Axios
                    .post(API.auth.changePassword, formData)
                    .then((res) => {

                        this.setState({
                            submitting:false,
                            submitMessage:""
                        });
                        if (res.data.success) {

                            alert("Password changed successfully.")

                            this.setState({
                                newpass: "",
                                newpass2: "",
                                oldPass: ""
                            });
                        }

                    }).catch(error => {

                    this.setState({
                        submitting:false,
                        submitMessage:""
                    });

                    console.log(error)
                    if (error.response)
                    {
                        if (error.response.data.error) {

                            alert(error.response.data.error)
                        } else{
                            alert("Could not change password.  Please check connection and try again.")
                        }
                    }else{
                        alert(error)
                    }

                });
            } else {
                alert("New password and re-type password fields do not match, please try again.")
            }


        } else {
            alert("Please enter your new password, re-type it, and enter your current password.  New password must be 6 or more characters.")
        }



    }

    render() {


        return(
            
                <div className="cell small-12">
                    <div className="changePass">
                        <div className="title">Change Your Password</div>
                        <div className="label">Type your new password</div>
                        <input className="pass-field" type="text" name="newpass" placeholder="New Password" value={this.state.newpass} onChange={this.handleChange}/>

                        <div className="label">Re-type your new password</div>

                        <input className="pass-field" type="text" name="newpass2" placeholder="New Password" value={this.state.newpass2} onChange={this.handleChange}/>

                        <div className="label">Type your current password</div>

                        <input className="pass-field" type="text" name="oldPass" placeholder="New Password"  value={this.state.oldPass} onChange={this.handleChange}/>

                        <div className="confirm"><div className="av-button" onClick={() => this.confirmClicked()}>Confirm</div></div>
                    </div>

                </div>


            

        );
    }


}
export default ChangePass;
