import Axios from "axios";
import {API} from "../../api/APIClient"
import React from "react";

class Fault extends React.Component {


    constructor(props) {
        super(props);

        this.state = {
            faults: undefined,
            adding: undefined
        };
        this.handleChange = this.handleChange.bind(this);
    }


    componentDidMount() {

        this.getProductsList()
    }

    expandOrContract()
    {
        var newExpanded = !this.state.expanded
        this.setState({expanded : newExpanded});

        if (newExpanded && this.state.products === undefined) {
            this.getProductsList()

        }
    }

    handleChange(event) {

        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });


    }

    getProductsList() {
        const formData = new FormData();
        formData.append('id', this.props.id);

        Axios
            .post(API.faults.getProductsForFault, formData)
            .then((res) => {

                if (res.data.success) {

                    this.setState({
                        products: res.data.data
                    })
                }
            })
    }

    cancelAdd() {

        this.setState({adding: false})
    }

    addNewProduct() {

        this.setState({adding: true})
    }

    deleteClicked(fixID)
    {
        const formData = new FormData();
        formData.append('id', fixID);

        Axios
            .post(API.faults.deleteFix, formData)
            .then((res) => {

                if (res.data.success) {
                    this.getProductsList()

                } else {
                    var msg = "Error deleting product, please check your connection and try again."
                    alert(msg)
                }
            }).catch(error => {

            var msg = "Error deleting product. "

            if (error.response) {
                if (error.response.data.error) {

                    msg = msg + error.response.data.error

                }

                alert(msg)
            } else {
                alert(error)

            }
        })
    }
    saveNewProduct() {

        if (this.state.product_name && this.state.product_link) {
            const formData = new FormData();
            formData.append('name', this.state.product_name);
            formData.append('link', this.state.product_link);
            formData.append('id', this.props.id);

            Axios
                .post(API.faults.addFix, formData)
                .then((res) => {

                    if (res.data.success) {
                        this.setState({adding: false, product_name:"", product_link: ""})
                        this.getProductsList()

                    } else {
                        var msg = "Error saving product, please check the details entered and try again."
                        alert(msg)
                    }
                }).catch(error => {

                var msg = "Error saving product. "

                if (error.response) {
                    if (error.response.data.error) {

                        msg = msg + error.response.data.error

                    }

                    alert(msg)
                } else {
                    alert(error)

                }
            })
        } else {
            alert("Please enter a Product Name and URL")
        }
    }

    render() {
        return (<div className="cell small-12">
            <div className="expandbox">
                <div className="black-arrow" onClick={() => this.expandOrContract()}>{this.state.expanded ? <span className='innerarrow down'></span> : <span className='innerarrow'></span>}</div>
                <div className="title"  onClick={() => this.expandOrContract()}><div className="cell small-5 no-pad">{this.props.name}</div>
                    <div className="cell small-7 no-pad">{this.state.products && this.state.products.length+" products"}</div></div>

                {this.state.expanded &&

                <div>

                    {this.state.products ?

                        <div>
                            {this.state.products.map((product, c) => {

                                return <div className="cell small-12 location small-pad">
                                    <div className="cell small-1 no-pad" />
                                    <div key={c} className="cell small-1 no-pad">
                                        {c+1}
                                    </div>
                                    <div className="cell small-5 no-pad">
                                        {product.productName}
                                    </div>
                                    <div className="cell small-5 no-pad">
                                        {product.productLink}
                                    </div>
                                        <div className="cell small-1 no-pad delete" onClick={() => this.deleteClicked(product.id)}>
                                            &nbsp;
                                        </div>

                                </div>

                            })}
                            <div className="cell small-12" />
                            {this.state.adding ?
                                <div>
                                <div className="cell small-6 small-pad">Product Name</div><div className="cell small-6 small-pad">Product URL</div>
                                <div className="cell small-6 small-pad">
                                    <input className="edit-field" type="text" name="product_name" placeholder="Product Name" value={this.state.product_name} onChange={this.handleChange}/>
                                </div><div className="cell small-6 small-pad">
                                    <input className="edit-field" type="text" name="product_link" placeholder="Product URL" value={this.state.product_link} onChange={this.handleChange}/>

                                </div>
                                    <div style={{padding: "4px"}}>
                                    <div className="av-button" onClick={() => this.cancelAdd()}>Cancel</div>
                                    <div className="av-button" onClick={() => this.saveNewProduct()}>Submit</div>
                                    </div>
                                </div>
                                :
                                <div className="av-button" onClick={() => this.addNewProduct()}>+ Add a product</div>
                            }

                        </div>

                        : <div><div className="cell small-12" /> Loading....</div>

                    }


                </div>
                }
            </div>

        </div>)
    }
}

export default Fault;