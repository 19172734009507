import React from 'react'
import { BrowserRouter} from "react-router-dom";
import {Route, Switch} from "react-router-dom";
import LoginScreen from "./login/LoginScreen"
import {API, APIHelper} from '../api/APIClient'
import {SECTIONS} from '../resources/strings'
import NavBar from './nav/NavBar'
import AppSection from './AppSection'
import Axios from "axios";
import Footer from './nav/Footer'


class DashBoard extends React.Component {
    constructor(props) {
        super(props);

        var user = null;
        const usrStr = localStorage.getItem('AVUser');
        if (usrStr != null)
        {
            user = JSON.parse(usrStr)

            //alert("setting to " + user.id + " " + user.token);
            APIHelper.setAuthDetails(user.token, user.id);

        }

        this.state = {
            currentSection: SECTIONS.CLIENTS,
            unread:0,
            user: user};
    }

    updateCurrentSection(sect)
    {
        this.setState({currentSection: sect})
    }

    handleLogin(user)
    {
        APIHelper.setAuthDetails(user.token, user.id);
        localStorage.setItem("AVUser", JSON.stringify(user));
        var showWelcomeMsg = false
        if (user.note_shown && user.note_shown === "0" && user.note && user.note.length > 1) {
            var showWelcomeMsg = true
        }
        this.setState({user : user, loginExpires : 3600, showWelcomeMsg: showWelcomeMsg} );
    }

    handleLogout()
    {
        localStorage.removeItem("TopFilters");
        localStorage.removeItem("ClientFilters");
        localStorage.removeItem("AVUser");
        this.setState({user : null, loginExpires : undefined});
        APIHelper.removeAuthDetails();
    }

    handleMenuClick(section) {

        this.setState({
            currentSection: section,
            currentSubScreen: null});
    }

    getUnread()
    {
        const formData = new FormData();

        Axios
            .post(API.notifications.getUnreadCount, formData)
            .then((res) => {

                if (res.data.success) {

                    this.setState({

                        unread: res.data.data
                    })
                }
            })
    }


    componentDidMount()
    {
        this.getUnread()
    }


    getLoginExpiry()
    {
        const formData = new FormData();

        Axios
            .post(API.auth.getLoginExpiry, formData)
            .then((res) => {

                if (this.state.timeoutID !== undefined){
                    clearTimeout(this.state.timeoutID)
                }
                var loginExpires = undefined
                var timeoutID = undefined
                if (res.data.success) {
                    loginExpires  = res.data.expires
                    if (loginExpires > 3600) {
                        loginExpires = 3600
                    }

                    if (loginExpires > 0) {
                         timeoutID = setTimeout(function() {
                            this.getLoginExpiry()
                        }.bind(this), loginExpires*1000)
                    }

                }


                this.setState({

                    loginExpires: loginExpires,
                    fetchingExpriy: false,
                    loginChecked: new Date(),
                    timeoutID: timeoutID

                })

            })
    }

    handleWelcomeShown(){
        const formData = new FormData();
        formData.append('userID', this.state.user.id);

        Axios
            .post(API.settings.setWelcomeShown, formData)
            .then((res) => {

            })
    }


    render() {

        if (this.state.user == null)
        {
            return (
                <BrowserRouter>

                    <Switch>
                        <Route path={'/'}  render={(props) => <LoginScreen
                            loginHandler={(user) => this.handleLogin(user)}
                            {...props} />}  />

                    </Switch>
                </BrowserRouter>
            );

        } else {

            if (this.state.loginExpires === undefined){
                if (! this.state.fetchingExpriy) {
                    this.setState({
                        fetchingExpriy: true
                    }, this.getLoginExpiry())
                }
            } else {
                if (this.state.loginExpires === -1) {
                    this.handleLogout()
                } else {
                    if (this.state.loginChecked !== undefined){
                        let sinceCheck = this.state.loginChecked.getTime() - new Date().getTime();
                        if ((this.state.loginExpires - sinceCheck) < 0) {
                            //recheck
                            this.setState({
                                loginExpires: undefined,
                                fetchingExpriy: true,
                                loginChecked: undefined
                            }, this.getLoginExpiry())
                        }
                    }

                }
            }
            return (
                <BrowserRouter>
                    <NavBar
                            menuClickHandler={(section) => this.handleMenuClick(section)}
                            user={this.state.user}
                            unread = {this.state.unread}
                            showWelcomeMsg = {this.state.showWelcomeMsg}
                            welcomeHandler={() => this.handleWelcomeShown()}
                            logoutHandler={() => this.handleLogout()}
                            currentSection={this.state.currentSection}

                    />

                    <AppSection
                                sectionChangeHandler={(sect) => this.updateCurrentSection(sect)}
                                user={this.state.user}
                                userHandler={(usr) => this.updateUserObject(usr)}
                                unreadUpdater = {() => this.getUnread()}

                    />
                    <Footer />
                </BrowserRouter>
            );
        }

    }
}

export default DashBoard;
