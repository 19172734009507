import React from 'react'


class ClientVenueHead extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            clients: [],
            search:""
        };
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(event) {

        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
        this.props.searchTextChanged(value);

    }


    render() {

        return(<div className="cell small-12 no-pad">

            <div className="clientsvenues clienthead">
                <div className="cell small-8 no-pad">
                    <div className="title">Clients & Venues</div>
                </div>
                <div className="cell small-4 no-pad">
                    <div className="search"><input type="text" placeholder="Search"  name="search" value={this.state.search} onChange={this.handleChange}/></div>
                </div>
                <div className="clearfix" />
            </div>
        </div>);
    }
}

export default ClientVenueHead;
