import Axios from "axios";
import {API} from "../../api/APIClient"
import React from "react";


class CSVSection extends React.Component {


    constructor(props) {
        super(props);

        this.state = {
            uploadType:"Products",
            selectedFile: undefined
        };
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(event) {

        const target = event.target;
        const value = target.value;
        const name = target.name;
        if (name === "file") {

            this.setState({
                selectedFile: event.target.files[0]
            });
        }
        this.setState({
            [name]: value
        });


    }

    upload()
    {
        if (this.state.selectedFile) {
            const formData = new FormData()
            formData.append('file', this.state.selectedFile)
            formData.append('type', this.state.uploadType)

            Axios
                .post(API.export.upload, formData)
                .then((res) => {


                    alert("Thank you, csv file uploaded. "+res.data.inserts+ " rows added." )
                }).catch(error => {

                var msg = "Error uploading file. "

                if (error.response)
                {
                    if (error.response.data.error) {

                        msg = msg + error.response.data.error

                    }

                    alert(msg)
                }else{
                    alert(error)

                }

            })
        } else {
            alert("Please choose a csv file first.")
        }
    }

    componentDidMount() {


    }

    dlProducts() {
        this.dlCSV("products")
    }

    dlClients() {
        this.dlCSV("service_client")
    }

    dlFaultTypes() {
        this.dlCSV("service_fault_types")
    }

    dlLocations() {
        this.dlCSV("service_location")
    }

    dlUsers() {
        this.dlCSV("users")
    }

    dlPortalUsers() {
        this.dlCSV("portal_users")
    }


    dlCSV(table){

        const FileDownload = require('js-file-download');
        const formData = new FormData();
        formData.append('table', table);

        Axios
            .post(API.export.getCSV, formData)
            .then((response) => {
                FileDownload(response.data, table+'.csv');
            }).catch(error => {

            var msg = "Error fetching file. "

            if (error.response) {
                if (error.response.data.error) {

                    msg = msg + error.response.data.error

                }

                alert(msg)
            } else {
                alert(error)
            }
        });

    }


    render() {

        return(
            <div className="admingrid">

                <div className="cell small-12 admin-wrap">
                    <div className="admin-set-box">
                        <div className="cell small-12 title-box">
                            <div className="title">Upload a CSV file <span onClick={()=>this.setState({"showingInstructions": true })}  style={{fontSize:"12px", textDecoration :"underline", cursor : "pointer"}}>(Click here for instructions)</span></div>
                        </div>
                        <div className="cell small-4">
                            <select className="edit-field" value={this.state.uploadType} name="uploadType" onChange={this.handleChange}>
                                <option value="Products">Products</option>
                                <option value="Clients">Clients</option>
                                <option value="Venues">Venues</option>
                            </select>
                        </div>
                        <div className="cell small-8">
                            <input type="file" className="edit-field" className="form-control" name="file" accept=".csv"  onChange={this.handleChange}/>
                            <div className="av-button" style={{float:"none"}} onClick={()=>this.upload()}>Upload</div>
                        </div>
                        <div className="clearfix" />
                    </div>
                </div>

                <div className="cell small-4"><div className="gridcell"><div className="title">Products List</div><div className="av-button"
                                                                                                                       onClick={() => this.dlProducts()}>Download</div></div></div>
                <div className="cell small-4"><div className="gridcell"><div className="title">Clients</div><div className="av-button"
                                                                                                                 onClick={() => this.dlClients()}>Download</div></div></div>
                <div className="cell small-4"><div className="gridcell"><div className="title">Fault Types</div><div className="av-button"
                                                                                                                     onClick={() => this.dlFaultTypes()}>Download</div></div></div>

                <div className="cell small-4"><div className="gridcell"><div className="title">Locations List</div><div className="av-button"
                                                                                                                       onClick={() => this.dlLocations()}>Download</div></div></div>
                <div className="cell small-4"><div className="gridcell"><div className="title">App Users List</div><div className="av-button"
                                                                                                                 onClick={() => this.dlUsers()}>Download</div></div></div>
                <div className="cell small-4"><div className="gridcell"><div className="title">Dashboard Users List</div><div className="av-button"
                                                                                                                     onClick={() => this.dlPortalUsers()}>Download</div></div></div>

                {( this.state.showingInstructions ) && <div className="modal" >
                    <div className="modal-contact-box main-wrap">
                        <div style={{textDecoration:"underline"}} onClick={()=>this.setState({"showingInstructions":false})}>Close</div>
                        <div>&nbsp;</div>

                        <b>CSV Upload Instructions</b>
                        <div>&nbsp;</div>
                        <div style={{textAlign:"left"}} >This section of the dashboard enables you to upload data to, or download from the dashboard's database tables.
                        To upload data you will need to create a CSV file containing the relevant data that you wish to upload to the system.  Note that the upload data functionality
                         will never overwrite any of the existing data stored within the Avani database.  The upload data functionality will only ever append new data to the end of the relevant Avani
                        database table, depending on which upload option was selected prior to sending the CSV file.</div>
                        <div>&nbsp;</div>

                        <div  style={{textAlign:"left"}}>There are 3 types of data that can be uploaded to the system - Products, Clients and Venues.  Each of these options will add data to the end of the relevant table in the Avani database.
                        To select which type of data to upload, use the drop-down list underneath the 'Upload a CSV file' title and select your preferred option.
                            The next step is to create the CSV file with the additional data that you wish to upload.</div>

                        <div>&nbsp;</div>
                        <div  style={{textAlign:"left"}}>Products: to upload additional products data create a spreadsheet with essentially the same format as the csv file you get by clicking 'Download'
                            on the Products List section of this screen.  So the first column is the database ID, however for the upload this can simply be left blank and the system will automatically assign a new ID.  The
                            second column is the Product Name, so enter the name of the product you wish to add in the second column.  Continue to add rows to the spreadsheet until you have added all of the products
                            you wish to append to the products table in the database.  Now save the spreadsheet as a .csv file and click on the Choose File button on this screen.  Click the Upload button and your file
                            will be sent up to the server and processed, and you will get a message back from the server confirming the success of the upload, and informing you how many rows have been added to the
                            database. The additional products will now be live in the database.</div>
                        <div>&nbsp;</div>
                        <div  style={{textAlign:"left"}}>Clients: to upload additional clients to the server create a spreadsheet with essentially the same format as the csv file you get by clicking 'Download'
                            on the Clients List section of this screen.  The first column is the database ID, however for the upload this can simply be left blank and the system will automatically assign a new ID.  The
                            second column is the Client Name, so enter the name of the client you wish to add in the second column. In the third column enter the Lead Brewer.  In the fourth column enter the TSP Name. In the fifth column enter the TSP Phone number.
                            In the sixth column enter the TSP Email Address. In the seventh column enter a 1 if you want the client to be live or a 0 if you do not want the client to be live.
                            Continue to add rows to the spreadsheet until you have added all of the clients
                            you wish to append to the clients table in the database.  Now save the spreadsheet as a .csv file and click on the Choose File button on this screen.  Click the Upload button and your file
                            will be sent up to the server and processed, and you will get a message back from the server confirming the success of the upload, and informing you how many rows have been added to the
                            database. The additional clients will now have been added to the database.</div>
                        <div>&nbsp;</div>
                        <div  style={{textAlign:"left"}}>Clients: to upload additional clients to the server create a spreadsheet with essentially the same format as the csv file you get by clicking 'Download'
                            on the Clients List section of this screen.  The first column is the database ID, however for the upload this can simply be left blank and the system will automatically assign a new ID.  The
                            second column is the Client Name, so enter the name of the client you wish to add in the second column. In the third column enter the Lead Brewer.  In the fourth column enter the TSP Name. In the fifth column enter the TSP Phone number.
                            In the sixth column enter the TSP Email Address. In the seventh column enter a 1 if you want the client to be live or a 0 if you do not want the client to be live.
                            An example line of the .csv file would look like this - <br/>"","UK Pub Group ","Molson Coors","Molson Coors Tech Services","0121 123123","mark@molsoncoors.com","1",<br/>

                            Continue to add rows to the spreadsheet until you have added all of the clients
                            you wish to append to the clients table in the database.  Now save the spreadsheet as a .csv file and click on the Choose File button on this screen.  Click the Upload button and your file
                            will be sent up to the server and processed, and you will get a message back from the server confirming the success of the upload, and informing you how many rows have been added to the
                            database. The additional clients will now have been added to the database.</div>
                        <div>&nbsp;</div>
                        <div  style={{textAlign:"left"}}>Venues: Create a spreadsheet where the first column is the database ID, however for the upload this can simply be left blank and the system will automatically assign a new ID.  The
                            second column is the Client ID, here you need to enter the number shown in the 1st column of the clients list download file next to the client you want to add these new venues to.
                            In the third column enter the Location Name for the new venue/location.  In the fourth column enter the 1st line of the address of the new location. In the fifth column enter the second line of the address of the new location.
                            In the sixth column enter the town of the new location. In the seventh column enter the county of the new location. In the eighth column enter the postcode of the new location.
                            In the ninth column enter the contact name form the location.  In the tenth column enter the contact email address for the location.  in the eleventh column enter the contact telephone number for the location.
                            In the twelfth column enter 1 if you want the location to be live or a 0 if you do not want the location to be live.
                            An example line for this upload would look like this <br/>
                            "","1","Rockley Park","Rockley Park Holiday Centre","Napier Rd",,"Hamworthy","BH15 4LZ","Ben Solomon",,"01202 673359",,"1",<br/>
                            Continue to add rows to the spreadsheet until you have added all of the locations
                            you wish to append to the locations table in the database.  Now save the spreadsheet as a .csv file and click on the Choose File button on this screen.  Click the Upload button and your file
                            will be sent up to the server and processed, and you will get a message back from the server confirming the success of the upload, and informing you how many rows have been added to the
                            database. The additional location will now have been added to the database.</div>
                        <div>&nbsp;</div>
                        <div style={{textDecoration:"underline"}} onClick={()=>this.setState({"showingInstructions":false})}>Close</div>

                    </div>
                </div>}
                    </div>

        );
    }
}

export default CSVSection;