import React from 'react'
import {BUTTON_MSG, CLIENT_TABS} from '../../../resources/strings'


function VenueLevelHeader(props) {

//            <div className="search"><input type="text" placeholder="Search"/></div>

    var showEditBtn = false
    var showDoneBtn = false
    if (props.user.admin == "1" && props.currentTab === CLIENT_TABS.SINGLE_VISIT) {

        if (props.buttonMessage === BUTTON_MSG.EDIT_REPORT)
        {
            showEditBtn = true
        } else {
            var showDoneBtn = true
        }
    }
    return(
        <div className="clientsheader">
            <div className="search">
                {showEditBtn &&

                <div>
                    <div className="av-button"
                         onClick={() => props.buttonMessageHandler(undefined)}>Done
                    </div>

                </div>
                }
                {showDoneBtn &&
                <div>
                    <div className="av-button"
                         onClick={() => props.buttonMessageHandler(BUTTON_MSG.PDF_REPORT)}>View PDF
                    </div>
                    <div className="av-button"
                         onClick={() => props.buttonMessageHandler(BUTTON_MSG.EDIT_REPORT)}>Edit
                    </div>

                </div>
                }
            </div>
            <img className="printlogo" src={require('../../../assets/img/avani_logo.svg').default} />
            <div className="title">{props.venue.name}</div>
            {props.forceClientSelection ?
                <div className="bread"><span>Overall summary</span> > <span
                    className="crumb" onClick={() => props.backClickHandler()}>Client</span> > Venue</div>
                :
                <div className="bread"><span className="crumb" onClick={() => props.backToTopClickHandler()}>Overall summary</span> > <span
                    className="crumb" onClick={() => props.backClickHandler()}>{props.client.name}</span> > {props.venue.name}</div>
            }
            <div className="tabs">
                {props.currentTab === CLIENT_TABS.SINGLE_VISIT ? <div className="selected">{CLIENT_TABS.SINGLE_VISIT}</div> : <div onClick={() => props.tabClickHandler(CLIENT_TABS.SINGLE_VISIT)}>{CLIENT_TABS.SINGLE_VISIT}</div>}
                {props.currentTab === CLIENT_TABS.TRENDS ? <div className="selected">{CLIENT_TABS.TRENDS}</div> : <div onClick={() => props.tabClickHandler(CLIENT_TABS.TRENDS)}>{CLIENT_TABS.TRENDS}</div>}
                {props.currentTab === CLIENT_TABS.VISITS ? <div className="selected">{CLIENT_TABS.VISITS}</div> : <div onClick={() => props.tabClickHandler(CLIENT_TABS.VISITS)}>{CLIENT_TABS.VISITS}</div>}
                {props.currentTab === CLIENT_TABS.PRODUCTS ? <div className="selected">{CLIENT_TABS.PRODUCTS}</div> : <div onClick={() => props.tabClickHandler(CLIENT_TABS.PRODUCTS)}>{CLIENT_TABS.PRODUCTS}</div>}
                {props.currentTab === CLIENT_TABS.VENUE_DETAILS ? <div className="selected">{CLIENT_TABS.VENUE_DETAILS}</div> : <div onClick={() => props.tabClickHandler(CLIENT_TABS.VENUE_DETAILS)}>{CLIENT_TABS.VENUE_DETAILS}</div>}

            </div>


        </div>

    );

}
export default VenueLevelHeader;
