import React from 'react'
import {CLIENT_TABS} from '../../resources/strings'


function ClientLevelHeader(props) {

//            <div className="search"><input type="text" placeholder="Search"/></div>

    return(
        <div className="clientsheader">
            <div className="title">{props.client.name}</div>
            {props.forceClientSelection ?
                <div className="bread"><span>Overall summary</span> > Client</div>
                :
                <div className="bread"><span className="crumb" onClick={() => props.backToTopClickHandler()}>Overall summary</span> > {props.client.name}</div>}

            <div className="tabs">
                {props.currentTab === CLIENT_TABS.OVERVIEW ? <div className="selected">{CLIENT_TABS.OVERVIEW}</div> : <div onClick={() => props.tabClickHandler(CLIENT_TABS.OVERVIEW)}>{CLIENT_TABS.OVERVIEW}</div>}
                {props.currentTab === CLIENT_TABS.VENUE_LIST ? <div className="selected">{CLIENT_TABS.VENUE_LIST}</div> : <div onClick={() => props.tabClickHandler(CLIENT_TABS.VENUE_LIST)}>{CLIENT_TABS.VENUE_LIST}</div>}
                {props.currentTab === CLIENT_TABS.PRODUCTS ? <div className="selected">{CLIENT_TABS.PRODUCTS}</div> : <div onClick={() => props.tabClickHandler(CLIENT_TABS.PRODUCTS)}>{CLIENT_TABS.PRODUCTS}</div>}
                {props.currentTab === CLIENT_TABS.GROUP_TRENDS ? <div className="selected">{CLIENT_TABS.GROUP_TRENDS}</div> : <div onClick={() => props.tabClickHandler(CLIENT_TABS.GROUP_TRENDS)}>{CLIENT_TABS.GROUP_TRENDS}</div>}

            </div>


        </div>

    );

}
export default ClientLevelHeader;
