import React from 'react'
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import Paper from "@material-ui/core/Paper/Paper";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TablePagination from "@material-ui/core/TablePagination";


class VenueVisitTable extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            page: 0,
            rowsPerPage: 25,
            visits: props.visits,
            tableData: props.visits ? props.visits.length === 0 ? "No visit data found for this Venue" : this.processTableData(props.visits) : "No visit data found for this Venue",
        };

        this.deleteRow = this.deleteRow.bind(this);
    }

    processTableData(visits) {
        return visits.map((row, c) => {
            var total = 0;
            var aCount = 0;
            var scoreTxt="";

            if (row.pre !== undefined) {
                row.pre.map((pre, c) => {
                    if (pre.preclean.length === 1)
                    {
                        total += parseInt(pre.precleanCount);
                    }
                    if (pre.preclean === "A")
                    {
                        aCount += parseInt(pre.precleanCount);
                    }

                })
                if (total > 0) {
                    scoreTxt = Math.round(aCount/total * 100) + "%";
                } else {
                    scoreTxt = "0%"
                }
            }



            return (
                <TableRow key={c}>
                    <TableCell component="th" scope="row">
                        {row.date}
                    </TableCell>
                    <TableCell align="center">{scoreTxt}</TableCell>
                    <TableCell align="center">{row.issueCount}</TableCell>
                    <TableCell align="center">
                        <div
                            onClick={() => this.props.didSelectVisit(row.id)}
                            className="av-button"
                            style={{ padding: '2px 30px' }}
                        >
                            View
                        </div>
                        {this.props.user.admin === "1" &&
                            <div
                                onClick={() => this.deleteRow(c, row.id)}
                                className="av-button"
                                style={{ padding: '2px 30px', backgroundColor: '#E23F33' }}
                            >
                                Delete
                            </div>
                        }
                    </TableCell>
                </TableRow>
            );
        });
    }

    deleteRow(index, visitID) {
        if (window.confirm("Are you sure you want to delete the visit?")) {
            const updatedVisits = this.state.visits.filter(item => item.id !== visitID);
            this.props.didDeleteVisit(visitID, index);
            this.setState({ visits: updatedVisits, tableData: this.processTableData(updatedVisits) });
        }
    }


    render() {

        return (<div>


            <div className="cell small-12">

                <TableContainer component={Paper}>
                    <Table  size="small" aria-label="clean results">
                        <TableHead>
                            <TableRow>
                                <TableCell>Date</TableCell>
                                <TableCell align="center">Pre-Clean A Score</TableCell>
                                <TableCell align="center">Number of Issues</TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.state.tableData === undefined ? <TableRow><TableCell>Loading...</TableCell></TableRow> : this.state.tableData.slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage)}
                        </TableBody>
                    </Table>

                </TableContainer>
                {this.state.tableData &&
                    <TablePagination
                        rowsPerPageOptions={[15, 25, 30, 100]}
                        component={Paper}
                        count={this.state.tableData === undefined ? 0 : this.state.tableData.length}
                        rowsPerPage={this.state.rowsPerPage}
                        page={this.state.page}
                        onChangePage={(event, newPage) => {

                            this.setState({page: newPage})
                        }}
                        onChangeRowsPerPage={(event) => {
                            this.setState({page: 0, rowsPerPage : parseInt(event.target.value, 10)})
                        }}
                    />}
            </div>
        </div>)
    }

}

export default VenueVisitTable;

