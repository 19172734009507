import React from "react";
import Axios from "axios";
import {API} from "../../../api/APIClient";

class BarResult extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
        }
        this.handleChange = this.handleChange.bind(this);

    }



    componentDidMount() {
        this.getLineResults();
    }

    handleChange(event) {

        const target = event.target
        const value = target.value
        const namearr = target.name.split("_");
        if (namearr.length !== 2) {
            return;
        }
        const name = namearr[0];
        const index = namearr[1];

        var cleanResults = this.state.barcleanResults
        var row = cleanResults[index]
        row[name] = value
        cleanResults[index] = row
        this.setState({
            barcleanResults: cleanResults,
            saved: false
        });

    }


    getLineResults()
    {
        const formData = new FormData();
        formData.append('visitID', this.props.visitID);
        formData.append('barID', this.props.barID);

        Axios
            .post(API.locations.resultsForBar, formData)
            .then((res) => {

                if (res.data.success) {

                    this.setState({
                        barcleanResults: res.data.data
                    })
                }
            })
    }

    saveChanges() {
        if (this.state.barcleanResults) {
            const formData = new FormData();
            formData.append('reportID', this.props.visitID);
            formData.append('barcleanResults', JSON.stringify(this.state.barcleanResults));

            Axios
                .post(API.visits.editBarResult, formData)
                .then((res) => {

                    if (res.data.success) {

                        this.setState({
                            saving: false,
                            saved: true
                        })


                    } else {
                        var msg = "Error saving results, please check the details entered and try again."
                        alert(msg)
                        this.setState({
                            saving: false
                        })

                    }
                }).catch(error => {

                var msg = "Error saving results. "
                this.setState({
                    saving: false
                })
                if (error.response) {
                    if (error.response.data.error) {
                        msg = msg + error.response.data.error
                    }
                    alert(msg)
                } else {
                    alert(error)

                }
            })
        }

    }

    render() {

        return (<div style={{textAlign: "center"}}>
            <table cellPadding={6} style={{width: "100%"}}><tr bgcolor="#23A6A6" style={{color:"#ffffff", fontWeight : "bold"}}><td>Tap</td><td>Product</td><td>Pre</td><td>Post</td><td>pH</td></tr>
            {this.state.barcleanResults && this.state.barcleanResults.map((row, c) => {

                return <tr bgcolor="#dedede">
                    <td>{row.linenumber}</td>
                    <td>{row.product}</td>
                    <td>
                        <select className="edit-field" value={row.preclean} name={"preclean_"+c} onChange={this.handleChange}>
                            <option value=""></option>
                            <option value="A">A</option>
                            <option value="B">B</option>
                            <option value="C">C</option>
                            <option value="D">D</option>
                        </select></td>
                    <td><select className="edit-field" value={row.postclean} name={"postclean_"+c} onChange={this.handleChange}>
                        <option value=""></option>
                        <option value="A">A</option>
                        <option value="B">B</option>
                        <option value="C">C</option>
                        <option value="D">D</option>
                        <option value="U">U</option>
                    </select></td>
                    <td><select className="edit-field" value={row.phtest} name={"phtest_"+c} onChange={this.handleChange}>
                        <option value="0">No</option>
                        <option value="1">Yes</option>
                    </select></td>
                </tr>

            })}
        </table>
            {this.state.saving
                ?
                <div className="av-button">Saving...</div>
                :
                <div className="av-button" onClick={() => this.setState({saving: true,saved: false}, this.saveChanges())}>Save {this.props.barName}</div>
            }
            {this.state.saved && <div>Changes to {this.props.barName} saved successfully</div>}

        </div>);
    }


}
export default BarResult
