import React from "react";
import Axios from "axios";
import {API} from "../../../api/APIClient";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell/TableCell";
import {PART_REPORT_SECTIONS, WARRANTY_SECTIONS} from "../../../resources/strings";
import TableContainer from "@material-ui/core/TableContainer";
import Paper from "@material-ui/core/Paper/Paper";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TablePagination from "@material-ui/core/TablePagination";
import DatePicker from "react-datepicker";
import {red} from "@material-ui/core/colors";


class TechnicianReport extends React.Component {



    constructor(props) {
        super(props);
        let now = new Date()

        this.state = {
            page: 0,
            rowsPerPage: 25,
            brewers: [],
            brewer: -1,
            technicians: [],
            technician: -1,
            parts: [],
            fromDate: new Date(new Date().setMonth(now.getMonth() - 1)),
            toDate: now
        };
        this.handleChange = this.handleChange.bind(this);

    }



    handleChange(event) {

        const target = event.target;
        const name = target.name;

        this.setState({
            [name]: target.value
        });



    }
    componentDidMount() {

        this.processPartData()

        setTimeout(
            () => this.getBrewers(),
            80
        );
        setTimeout(
            () => this.getTechnicians(),
            110
        );
    }

    getTechnicians() {
        const formData = new FormData();
        formData.append('userID', this.props.userID);

        Axios
            .post(API.users.simpleTechniciansList, formData)
            .then((res) => {

                if (res.data.success) {

                    this.setState({
                        technicians: res.data.data
                    })
                }
            })
    }

    getBrewers() {
        const formData = new FormData();

        Axios
            .post(API.warranty.getBrewers, formData)
            .then((res) => {

                if (res.data.success) {

                    this.setState({
                        brewers: res.data.brewers,
                    })


                }
            })
    }

    getDatetimeDate(date) {

        let month = '0' +(date.getMonth()+1)
        let day = '0' + date.getDate()
        return date.getFullYear() + "-" + month.slice(-2) + "-"  + day.slice(-2) + " 00:00:01"
    }



    getDatetimeDateEndOfDay(date) {

        let month = '0' +(date.getMonth()+1)
        let day = '0' + date.getDate()
        return date.getFullYear() + "-" + month.slice(-2) + "-"  + day.slice(-2) + " 23:59:59"
    }



    getData() {

        if (this.state.brewer > 0 && this.state.technician > 0) {
            const formData = new FormData();
            formData.append('technicianID', this.state.technician);
            formData.append('brewerID', this.state.brewer);
            formData.append('fromDate', this.getDatetimeDate(this.state.fromDate));
            formData.append('toDate', this.getDatetimeDateEndOfDay(this.state.toDate));

            Axios
                .post(API.warranty.partStockReport, formData)
                .then((res) => {

                    if (res.data.success) {

                        this.setState({
                            parts: res.data.parts,
                        }, () => this.processPartData())


                    }
                })
        } else {
            alert("Please select a lead brewer and a technician")
        }

    }

    processPartData() {
        var processedParts = []
        var lastPart = undefined
        for (var c = 0; c < this.state.parts.length; c++) {
            var currentPart = this.state.parts[c]
            if ( lastPart == undefined || (lastPart.partID != currentPart.partID || lastPart.visitID != currentPart.visitID)) {
                currentPart.quantity = 1
                processedParts.push(currentPart)
                lastPart = currentPart
            } else {
                processedParts[processedParts.length - 1].quantity = processedParts[processedParts.length - 1].quantity + 1
            }

        }

        this.setState({
            tableData: processedParts.map((part, c) => {

                return <TableRow key={c}>
                    <TableCell component="th" scope="row">{part.partName}</TableCell>
                    <TableCell component="th" scope="row">{part.partCode}</TableCell>
                    <TableCell component="th" scope="row">{part.minStock}</TableCell>
                    <TableCell component="th" scope="row">{eval(part.stock) + eval(part.qty)}</TableCell>
                    <TableCell component="th" scope="row">{part.qty}</TableCell>
                    <TableCell component="th" scope="row">{part.stock < part.minStock ? <span style={{color:"red"}}><b>{part.stock}</b></span> :  part.stock }</TableCell>
                </TableRow>

            })
        })

    }


    fromDateChanged(date)
    {
        this.setState({fromDate: date})

    }


    toDateChanged(date)
    {
        this.setState({toDate: date})

    }


    render() {

        return (<div>
            <div className="clientsheader">
                <div className="tabs">
                    <div onClick={() => this.props.tabClickHandler(PART_REPORT_SECTIONS.BREWER_USAGE)}>{PART_REPORT_SECTIONS.BREWER_USAGE}</div>
                    <div className="selected">{PART_REPORT_SECTIONS.TECHNICIAN_STOCK}</div>
                </div>
            </div>
            <div className="cell small-12 admin-wrap">

                <div className="admin-set-box">

                    <div className="cell small-12 title-box">

                        <div className="cell small-3">

                            <span>Lead Brewer:</span>
                            <select className="edit-field" value={this.state.brewer} name="brewer" onChange={this.handleChange}>
                                <option value={-1}>Select brewer...</option>
                                {this.state.brewers.map((brewer, c) => {
                                    return <option value={brewer.id}>{brewer.brewerName}</option>
                                })}
                            </select>
                        </div>
                        <div className="cell small-3">
                            <span>Technician:</span>
                            <select className="edit-field" value={this.state.technician} name="technician" onChange={this.handleChange}>
                                <option value={-1}>Select technician...</option>
                                {this.state.technicians.map((tech, c) => {
                                    return <option value={tech.id}>{tech.name}</option>
                                })}
                            </select>
                        </div>

                        <div className="cell small-6">

                        </div>
                    </div>
                    <div className="cell small-12 title-box">

                        <div className="cell small-3">
                            <span>Date from: </span>
                            <DatePicker
                                dateFormat="dd/MM/yyyy"
                                className="edit-field"
                                selected={this.state.fromDate}
                                onChange={(date)=>this.fromDateChanged(date)}
                            />

                        </div>
                        <div className="cell small-3">

                            <span>Date to: </span>

                            <DatePicker
                                dateFormat="dd/MM/yyyy"
                                className="edit-field"
                                selected={this.state.toDate}
                                onChange={(date)=>this.toDateChanged(date)}
                            />
                        </div>

                        <div className="cell small-6">
                            <div className="av-button"  onClick={() => this.getData()}>Update Results</div>
                        </div>
                    </div>

                    <div className="clearfix" />

                </div>

                <div className="cell small-12 no-pad">

                    <TableContainer component={Paper}>
                        <Table  size="small" aria-label="clean results">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Product</TableCell>
                                    <TableCell>Type</TableCell>
                                    <TableCell>Min Stock Level</TableCell>
                                    <TableCell>Opening Stock</TableCell>
                                    <TableCell>Stock Used</TableCell>
                                    <TableCell>Stock Status</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {this.state.tableData === undefined ? <TableRow><TableCell>Loading...</TableCell></TableRow> : this.state.tableData.slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage)}
                            </TableBody>
                        </Table>

                    </TableContainer>
                    {this.state.tableData &&
                    <TablePagination
                        rowsPerPageOptions={[10, 25, 100]}
                        component={Paper}
                        count={this.state.tableData === undefined ? 0 : this.state.tableData.length}
                        rowsPerPage={this.state.rowsPerPage}
                        page={this.state.page}
                        onChangePage={(event, newPage) => {

                            this.setState({page: newPage})
                        }}
                        onChangeRowsPerPage={(event) => {
                            this.setState({page: 0, rowsPerPage : parseInt(event.target.value, 10)})
                        }}
                    />}
                </div>
            </div>




        </div>)

    }
}

export default TechnicianReport;