import React from "react";

function Venue(props) {



    return(


            <div className="cell small-12 location small-pad" title="Click to select venue">
                <div className="cell small-1 no-pad" />
                <div className="cell small-1 no-pad">
                    {props.venueID}
                </div>
                <div className="cell small-10 no-pad">
                    <span style={{cursor: "pointer", textDecoration: "underline"}}  onClick={() => props.didSelectVenue(props.venueID, props.locationName)}>{props.locationName}</span>
                </div>
            </div>


    );

}
export default Venue;