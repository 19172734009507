import React from 'react'
import MyPie from "../common/MyPie";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Axios from "axios";
import {API} from "../../api/APIClient";
import {Bar} from 'react-chartjs-2';


class ClientProduct extends React.Component {

    constructor(props) {
        super(props);


        this.state = {
            expanded: false,
            pieData: undefined,
            tableData: undefined,
            barChartData: undefined,
            page: 0,
            rowsPerPage: 25
        };
        //this.handleChange = this.handleChange.bind(this);
    }


    componentDidUpdate(prevProps) {
        if (prevProps.filters !== this.props.filters) {
            this.getSurveyData();
            this.getBarChartData();
        }
    }


    createData(name, calories, fat, carbs, protein) {
        return { name, calories, fat, carbs, protein };
    }

    expandOrContract()
    {
        var newExpanded = !this.state.expanded
        this.setState({expanded : newExpanded});
        if (newExpanded && this.state.pieData === undefined) {

            this.getSurveyData();
            if (this.props.showBarChart){
                this.getBarChartData()
            }
        }
    }


    getBarChartData() {
        const formData = new FormData();
        formData.append('productID', this.props.prod);

        if (this.props.client){
            formData.append('clientID', this.props.client.id);
        }
        if (this.props.venue) {
            formData.append('venueID', this.props.venue.id);
        }

        Axios
            .post(API.products.getDataForProductAndClient, formData)
            .then((res) => {

                if (res.data.success) {

                    this.setState({
                        barChartData: res.data.chartData
                    })
                }
            })
    }


    getSurveyData() {

        const formData = new FormData();
        formData.append('productID', this.props.prod);
        formData.append('userID', this.props.userid);
        var apiToCall = API.products.getDataForProduct2;

        let filters = Object.assign({},this.props.filters);

        if (this.props.client){
            filters.clients = {isOn: true, isRestrict: true, isExclude: false, list: [{id: this.props.client.id}]}
        }
        if (this.props.venue) {
            filters.venues = {isOn: true, isRestrict: true, isExclude: false, list: [{id: this.props.venue.id}]}
        }

        filters.products = {isOn: true, isRestrict: true, isExclude: false, list: [{id: this.props.prod}]}

        formData.append("filters", JSON.stringify(filters));

        Axios
            .post(apiToCall, formData)
            .then((res) => {

                if (res.data.success) {

                    this.setState({
                        pieData: res.data.pieData.map((segment, c) => {

                            var parsedVal = parseInt(segment.value, 10);
                            if (isNaN(parsedVal)) {

                                parsedVal = 0
                            }

                            return {title: segment.title, value: parsedVal, color: segment.color};

                        }),
                        tableData: res.data.tableData.map((row, c) => {

                            return <TableRow key={c}>
                                <TableCell component="th" scope="row"><span style={{textDecoration: "underline", cursor: "pointer"}} onClick={() => { this.props.didSelectVenue(row.clientID, row.clientName, row.locationID, row.loc)}}>{row.loc}</span></TableCell>
                                <TableCell>{row.date}</TableCell>
                                <TableCell>{row.pre}<span className={"dot_" + row.pre}></span></TableCell>
                            </TableRow>

                        })
                    })
                }
            })
    }



    render() {

        var noData = true
        var loading = true
        if (this.state.tableData !== undefined) {
            loading=false
            if (this.state.tableData.length > 0) {
                noData=false
            }
        }

        return(<div className="cell small-12 product-item">

            <div className="product">
                <div className="black-arrow" onClick={() => this.expandOrContract()}>{this.state.expanded ? <span className='innerarrow down'></span> : <span className='innerarrow'></span>}</div>

                <div className="title"  onClick={() => this.expandOrContract()}>{this.props.name}</div>

                {this.state.expanded &&

                    <span>

                {(noData === true) ? <div className="cell small-12">{loading ? "Loading..." : "This product currently has no results"}</div>
                    :
                    <div className="cell small-12">
                        <div className="cell small-7 ">

                            <TableContainer component={Paper}>
                                <Table  size="small" aria-label="clean results">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell><b>Venue</b></TableCell>
                                            <TableCell><b>Date</b></TableCell>
                                            <TableCell><b>Pre-Clean</b></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {this.state.tableData === undefined ? <TableRow><TableCell>Loading...</TableCell></TableRow> : this.state.tableData.slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage)}
                                    </TableBody>
                                </Table>

                            </TableContainer>
                            {this.state.tableData &&
                            <TablePagination
                                rowsPerPageOptions={[15, 25, 30, 100]}
                                component={Paper}
                                count={this.state.tableData === undefined ? 0 : this.state.tableData.length}
                                rowsPerPage={this.state.rowsPerPage}
                                page={this.state.page}
                                onChangePage={(event, newPage) => {

                                    this.setState({page: newPage})
                                }}
                                onChangeRowsPerPage={(event) => {
                                    this.setState({page: 0, rowsPerPage : parseInt(event.target.value, 10)})
                                }}
                            />}
                        </div>
                        <div className="cell small-5 pie-wrap">
                            {this.state.pieData === undefined ? <div>loading...</div> :
                                <div className="title pie-view" style={{textAlign: "center"}}>
                                    <MyPie hidePercent={true} data={this.state.pieData} />
                                </div>
                            }
                        </div>

                        {(this.props.showBarChart && this.state.barChartData) &&
                        <div className="cell small-12 ">
                            <div className="title" style={{textAlign: "center"}}>Pre-Clean results per venue</div>
                            <Bar
                                data={this.state.barChartData}
                                height={250}
                                options={{
                                    maintainAspectRatio: false,
                                    scales: {
                                        xAxes: [{
                                            stacked: true
                                        }],
                                        yAxes: [{
                                            stacked: true
                                        }]
                                    }
                                }}
                            />
                        </div>}
                    </div>
                }

                    </span>




                }

            </div>

        </div>);
    }

}

export default ClientProduct;