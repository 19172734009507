import { PieChart } from 'react-minimal-pie-chart';
import React from 'react';




class MyPie extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            selected: -1,
            lineWidth: 100
        };
        //this.handleChange = this.handleChange.bind(this);
    }


    render() {
        var fontSize = '5px'
        var postLbl = this.props.hidePercent ? "": "%"

        if (this.props.fontsize ==='big') {
            fontSize = '7px'
        }
        function isLight(hexColor) {
            hexColor = hexColor.replace('#', '')
            // Convert hex color to RGB values
            const r = parseInt(hexColor.substring(0,2), 16);
            const g = parseInt(hexColor.substring(2,4), 16);
            const b = parseInt(hexColor.substring(4,6), 16);

            // Calculate relative luminance
            const relativeLuminance = 0.2126 * r + 0.7152 * g + 0.0722 * b;

            // Determine if color is light or dark
            return relativeLuminance > 150;
        }
        return (
            <PieChart
                style={{
                    fontFamily:
                        '"Nunito Sans", -apple-system, Helvetica, Arial, sans-serif',
                    fontSize: fontSize,
                }}
                data={this.props.data}
                radius={PieChart.defaultProps.radius - 5}
                lineWidth={this.state.lineWidth}
                segmentsStyle={{ transition: 'stroke .3s', cursor: 'pointer' }}
                segmentsShift={(index) => (index === this.state.selected ? 6 : 0)}
                animate
                label={({ dataEntry }) => (dataEntry.value === 0) ? '' : dataEntry.title + ' - ' + dataEntry.value+ postLbl}
                labelPosition={75}
                labelStyle={(index) => {
                    let item = this.props.data[index]
                    return {
                        fill: isLight(item.color) ? '#000' : '#fff',
                        opacity: 1,
                        pointerEvents: 'none',
                    }
                }}
                onClick={(_, index) => {
                    if (this.state.selected === index){
                        this.setState({selected: -1});

                    } else {
                        this.setState({selected: index});
                    }
                }}
                onMouseOver={(_, index) => {
                    this.setState({hovered: index});
                }}
                onMouseOut={() => {
                    this.setState({hovered: undefined});
                }}
            />
        );
    }


}

export default MyPie;