import React from 'react'
import Axios from "axios";
import {API} from "../../api/APIClient";



class LocEmailSetting extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            submitting: false,
            savedVisible : false,
            permission: props.ticked

        };
        this.handleChange = this.handleChange.bind(this);
    }

    componentWillReceiveProps(nextProps) {

        if (this.state.permission !== nextProps.ticked){
            this.setState({ permission: nextProps.ticked });
        }
    }


    handleChange(event) {

        if (!this.state.submitting) {
            const target = event.target;
            const name = target.name;

            if (name === "permission") {
                const checked = target.checked;

                this.setState({
                    [name]: checked
                });

                this.saveSetting(checked);
            }
        }
    }






    saveSetting(setting)
    {
        this.setState({
            submitting:true,
            submitMessage:"Sending request..."
        });
        const formData = new FormData();
        formData.append('setting', setting ? 1 : 0);
        formData.append('locationID', this.props.locID);

        Axios
            .post(API.locations.setEmailSetting, formData)
            .then((res) => {

                if (res.data.success) {

                    this.setState({
                        submitting:false,
                        savedVisible:true,
                        submitMessage:""
                    });

                    if (this._timer != null) {
                        clearTimeout(this._timer)
                    }

                    this._timer = setTimeout(function(){
                        this.setState({savedVisible: false});
                        this._timer = null;
                    }.bind(this), 3000);
                }

            }).catch(error => {

            this.setState({
                submitting:false,
                submitMessage:""
            });

            console.log(error)
            if (error.response)
            {
                if (error.response.data.error) {

                    alert(error.response.data.error)
                }
            }else{
                alert(error)
            }

        });


    }


    componentWillUnmount() {
        clearTimeout(this._timer);
    }


    render() {


        return(
                <div className="cell small-12" style={{padding: "8px"}}>
                    {this.props.header && <h1>{this.props.header}</h1>}
                            <input  type="checkbox" name="permission" checked={this.state.permission} onClick={this.handleChange}/> {this.props.name}
                    {this.state.savedVisible && <span className="saved"> SAVED </span>}

                </div>



        );
    }


}
export default LocEmailSetting;
