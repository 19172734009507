import React from 'react'
import {CLIENT_TABS} from '../../resources/strings'
function ClientsHeader(props) {


    return(
        <div className="clientsheader">
            <div className="title">Your Dashboard</div>
            <div className="bread">Overall summary</div>
            <div className="tabs">
                {props.currentTab === CLIENT_TABS.OVERVIEW ? <div className="selected">{CLIENT_TABS.OVERVIEW}</div> : <div onClick={() => props.tabClickHandler(CLIENT_TABS.OVERVIEW)}>{CLIENT_TABS.OVERVIEW}</div>}
                {props.currentTab === CLIENT_TABS.CLIENT_LIST ? <div className="selected">{CLIENT_TABS.CLIENT_LIST}</div> : <div onClick={() => props.tabClickHandler(CLIENT_TABS.CLIENT_LIST)}>{CLIENT_TABS.CLIENT_LIST}</div>}
                {props.currentTab === CLIENT_TABS.PRODUCTS ? <div className="selected">{CLIENT_TABS.PRODUCTS}</div> : <div onClick={() => props.tabClickHandler(CLIENT_TABS.PRODUCTS)}>{CLIENT_TABS.PRODUCTS}</div>}

            </div>


        </div>

    );

}
export default ClientsHeader;
