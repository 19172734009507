import React from 'react'
import ChangePass from './ChangePass'
import NotificationSettings from './NotificationSettings'
import {SECTIONS} from "../../resources/strings";

class Settings extends React.Component {

    constructor(props) {
        super(props);

        this.SCREENS = {
            HOME: "settingsmenu",

        };


        this.state = {
            currentScreen: this.SCREENS.HOME,
            navStack: [],
        };


    }




    componentDidMount()
    {
        this.props.sectionChangeHandler(SECTIONS.SETTINGS)
    }





    handleItemClick (newScreen)
    {
        this.props.showMenuHandler(false);
        const oldScreen = this.state.currentScreen;
        const navStack = this.state.navStack.concat([oldScreen])
        this.setState({
            currentScreen: newScreen,
            navStack: navStack
        });
    }


    handleBackClick ()
    {
        const navStack = this.state.navStack.slice();

        if (navStack.length > 1)
        {
            this.props.showMenuHandler(false);
        } else {
            this.props.showMenuHandler(true);

        }
        const backScreen = navStack[navStack.length - 1];

        this.setState({
            currentScreen: backScreen,
            navStack: navStack.slice(0,navStack.length - 1)
        });
    }

    render(){

        switch (this.state.currentScreen) {

            case this.SCREENS.HOME :

                return(
                    <div className="main-wrap settings-section">
                        <div className="adminheader">
                            <div className="cell small-12">
                                <div className="title">Your Settings</div>
                            </div>
                            <div className="clearfix" />
                        </div>

                        <div className="cell small-12 settingsbg">
                            <ChangePass user={this.props.user} />
                        </div>

                        <div className="cell small-12"></div>

                        <div className="cell small-12 settingsbg">
                            <NotificationSettings user={this.props.user} />
                        </div>

                        <div className="cell small-12"></div>


                    </div>
                );

            default :
                return(<div>unknown screen</div>);
        }
    }


}

export default Settings;
