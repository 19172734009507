import React from 'react'
import Settings from './settings/Settings'
import Notifications from './notifications/Notifications'
import Admin from "./admin/Admin"
import OverviewLevel from "./clients/OverviewLevel";
import {SECTIONS} from '../resources/strings'
import {Route, Switch} from "react-router-dom";
import {API} from "../api/APIClient";
import Axios from "axios";
import ReactGA from 'react-ga'


const NotFound = () => <div>Page not found</div>;


class AppSection extends React.Component {


    constructor(props) {
        super(props);


        this.state = {
            singleClient: undefined
        };
    }


    componentDidMount()
    {
        this.checkSingleClient()
        ReactGA.pageview(window.location.pathname)
    }

    checkSingleClient()
    {


        const formData = new FormData();

        Axios
            .post(API.users.isSingleClientUser, formData)
            .then((res) => {

                if (res.data.success) {

                    this.setState({

                        singleClient: res.data.singleClient
                    })
                }
            })
    }


    render() {

        return (
            <Switch>

                <Route path={'/'+ SECTIONS.SETTINGS} exact render={ (props) => ( <Settings sectionChangeHandler={this.props.sectionChangeHandler} user={this.props.user} {...props} />)} />

                <Route path={'/'+ SECTIONS.NOTIFICATIONS} exact render={(props) => <Notifications {...props} sectionChangeHandler={this.props.sectionChangeHandler} unreadUpdater={this.props.unreadUpdater}/>} />

                <Route path={'/'+ SECTIONS.ADMIN} exact render={(props) => <Admin user={this.props.user}  sectionChangeHandler={this.props.sectionChangeHandler} unreadUpdater={this.props.unreadUpdater} {...props} />} />
                <Route exact path={'/'+ SECTIONS.CLIENTS+'/clid/:clientID'} exact render={(props, history) => <OverviewLevel sectionChangeHandler={this.props.sectionChangeHandler} singleClient={this.state.singleClient} user={this.props.user} {...props} />} />

                <Route exact path={'/'+ SECTIONS.CLIENTS+'/vid/:venueID'} exact render={(props, history) => <OverviewLevel sectionChangeHandler={this.props.sectionChangeHandler} singleClient={this.state.singleClient} user={this.props.user}  {...props} />} />

                <Route exact path={'/'+ SECTIONS.CLIENTS+'/rid/:reportID'} exact render={(props, history) => <OverviewLevel sectionChangeHandler={this.props.sectionChangeHandler} singleClient={this.state.singleClient} user={this.props.user}  {...props} />} />

                <Route exact path={'/'+ SECTIONS.ADMIN+'/:id'} render={(props) => <Admin user={this.props.user}  unreadUpdater={this.props.unreadUpdater} {...props}  sectionChangeHandler={this.props.sectionChangeHandler} /> } />
                <Route exact path={'/'+ SECTIONS.ADMIN+'/ev/:vcode'} render={(props) => <Admin user={this.props.user} unreadUpdater={this.props.unreadUpdater}  {...props}  sectionChangeHandler={this.props.sectionChangeHandler} /> } />

                <Route path={'/'} render={(props, history) => <OverviewLevel sectionChangeHandler={this.props.sectionChangeHandler} singleClient={this.state.singleClient} user={this.props.user}  reset={true}  {...props} />} />

                <Route component={NotFound} />
            </Switch>
        );
    }

}

export default AppSection;
