import React from 'react'
import Axios from "axios";
import {API} from "../../api/APIClient";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import Paper from "@material-ui/core/Paper/Paper";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TablePagination from "@material-ui/core/TablePagination";


class UsersTable extends React.Component {

    constructor(props) {
        super(props);

        this.state = {tableData: undefined,
            page: 0,
            rowsPerPage: 25};
        this.handleChange = this.handleChange.bind(this);
    }



    handleChange(event) {

            const target = event.target;
            const name = target.name;


            if (name === "adminsOnly" || name === "techsOnly") {
                const checked = target.checked;

                this.setState({
                    [name]: checked
                }, ()=>this.updateMatching());


            } else {
                this.setState({
                    [name]: target.value
                }, ()=>this.updateMatching());


            }



    }

    updateMatching()
    {
        if (this.state.search || this.state.adminsOnly || this.state.techsOnly) {
            var matchingUsers = []
            for (var c = 0; c < this.state.users.length; c++) {
                if (! this.state.search) {
                    matchingUsers.push(this.state.users[c])

                } else {
                    var searchTerms = this.state.search.split(" ");
                    for (var i = 0; i < searchTerms.length; i++) {

                        if ( this.state.users[c].surname.toLowerCase().includes(searchTerms[i].toLowerCase()) || this.state.users[c].firstname.toLowerCase().includes(searchTerms[i].toLowerCase()) ){
                            matchingUsers.push(this.state.users[c])
                            break
                        }
                    }
                }


            }

            if (this.state.adminsOnly || this.state.techsOnly){
                var adminMatchingUsers = []

                for (var c = 0; c < matchingUsers.length; c++) {
                    if (this.state.adminsOnly && this.state.techsOnly) {
                        if (parseInt(matchingUsers[c].admin) === 1 && parseInt(matchingUsers[c].technician) === 1){
                            adminMatchingUsers.push(matchingUsers[c])
                        }
                    } else {
                        if (this.state.adminsOnly) {
                            if (parseInt(matchingUsers[c].admin) === 1){
                                adminMatchingUsers.push(matchingUsers[c])
                            }
                        }
                        if (this.state.techsOnly) {
                            if (parseInt(matchingUsers[c].technician) === 1){
                                adminMatchingUsers.push(matchingUsers[c])
                            }
                        }
                    }


                }
                this.setState({
                    tableData: adminMatchingUsers.map((row, c) => {


                        return <TableRow key={c}>
                            <TableCell component="th" scope="row">{row.firstname} {row.surname}</TableCell>
                            <TableCell>{parseInt(row.technician) === 1 ?
                                <div>{parseInt(row.admin) === 1 ? "Admin Technician" : "Technician"}</div>
                                :
                                <div>{parseInt(row.admin) === 1 ? "Administrator" : "Client User"}</div>
                            }</TableCell>
                            <TableCell>
                                <div onClick={() => this.props.didSelectUser(row.id)} className="av-button"
                                     style={{padding: "2px 30px"}}>View
                                </div>
                            </TableCell>
                        </TableRow>

                    })})
            } else {
                this.setState({
                    tableData: matchingUsers.map((row, c) => {


                        return <TableRow key={c}>
                            <TableCell component="th" scope="row">{row.firstname} {row.surname}</TableCell>
                            <TableCell>{parseInt(row.technician) === 1 ?
                                <div>{parseInt(row.admin) === 1 ? "Admin Technician" : "Technician"}</div>
                                :
                                <div>{parseInt(row.admin) === 1 ? "Administrator" : "Client User"}</div>
                            }</TableCell>
                            <TableCell>
                                <div onClick={() => this.props.didSelectUser(row.id)} className="av-button"
                                     style={{padding: "2px 30px"}}>View
                                </div>
                            </TableCell>
                        </TableRow>

                    })
                })
            }

        } else {
            this.setState({
                tableData: this.state.users.map((row, c) => {


                    return <TableRow key={c}>
                        <TableCell component="th" scope="row">{row.firstname} {row.surname}</TableCell>
                        <TableCell>{parseInt(row.technician) === 1 ?
                            <div>{parseInt(row.admin) === 1 ? "Admin Technician" : "Technician"}</div>
                            :
                            <div>{parseInt(row.admin) === 1 ? "Administrator" : "Client User"}</div>
                        }</TableCell>
                        <TableCell>
                            <div onClick={() => this.props.didSelectUser(row.id)} className="av-button"
                                 style={{padding: "2px 30px"}}>View
                            </div>
                        </TableCell>
                    </TableRow>

                })
            })
        }

    }




    componentDidMount() {
        setTimeout(
            () => this.getUsers(),
            100
        );

    }


    getUsers() {
        const formData = new FormData();

        Axios
            .post(API.users.getUsers, formData)
            .then((res) => {

                if (res.data.success) {

                    if (res.data.data === false) {
                        this.setState({
                            tableData: [], users: []
                        })
                    } else {
                        this.setState({
                            users: res.data.data,
                            tableData: res.data.data.map((row, c) => {


                                return <TableRow key={c}>
                                    <TableCell component="th" scope="row">{row.firstname} {row.surname}</TableCell>
                                    <TableCell>{parseInt(row.technician) === 1 ?
                                        <div>{parseInt(row.admin) === 1 ? "Admin Technician" : "Technician"}</div>
                                        :
                                        <div>{parseInt(row.admin) === 1 ? "Administrator" : "Client User"}</div>
                                    }</TableCell>
                                    <TableCell>
                                        <div onClick={() => this.props.didSelectUser(row.id)} className="av-button"
                                             style={{padding: "2px 30px"}}>View
                                        </div>
                                    </TableCell>
                                </TableRow>

                            })

                        })
                    }
                }
            })
    }


    render() {

        return (<div className="cell small-12 admin-wrap">
            <div className="admin-set-box">
                <div className="cell small-12 title-box">
                    <div className="title">User List</div>
                </div>
                <div className="cell small-4 search">
                    <input type="text" placeholder="Search for users" name="search" value={this.state.search} onChange={this.handleChange}/>
                </div>
                
                <div className="cell small-4">
                    <input  type="checkbox" name="adminsOnly" checked={this.state.adminsOnly} onClick={this.handleChange}/> Only Show Administrators&nbsp;&nbsp;&nbsp;
                    <input  type="checkbox" name="techsOnly" checked={this.state.techsOnly} onClick={this.handleChange}/> Only Show Technicians
                </div>
                <div className="cell small-4">
                    <div className="av-button" onClick={() => this.props.didSelectUser(-1)}>Add New User</div>
                </div>
                

                <div className="clearfix" />
            </div>

            <div className="cell small-12 no-pad">

                <TableContainer component={Paper}>
                    <Table  size="small" aria-label="clean results">

                        <TableBody>
                            {this.state.tableData === undefined ? <TableRow><TableCell>Loading...</TableCell></TableRow> : this.state.tableData.slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage)}
                        </TableBody>
                    </Table>

                </TableContainer>
                {this.state.tableData &&
                <TablePagination
                    rowsPerPageOptions={[10, 25, 100]}
                    component={Paper}
                    count={this.state.tableData === undefined ? 0 : this.state.tableData.length}
                    rowsPerPage={this.state.rowsPerPage}
                    page={this.state.page}
                    onChangePage={(event, newPage) => {

                        this.setState({page: newPage})
                    }}
                    onChangeRowsPerPage={(event) => {
                        this.setState({page: 0, rowsPerPage : parseInt(event.target.value, 10)})
                    }}
                />}
            </div>
            
        </div>)
    }

}

export default UsersTable;

