import React from 'react'
import {ADMIN_SECTIONS, BUTTON_MSG} from "../../resources/strings";
import Axios from "axios";
import {API} from "../../api/APIClient";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell/TableCell";



class AdminHeader extends React.Component {

    constructor(props) {
        super(props);

        this.state = {clientText : "Client Details", venueText : "Venue Details", userText:"User Details"}
    }


    getClientName(clientID) {

        const formData = new FormData();
        formData.append('clientID', clientID);

        Axios
            .post(API.clients.getClientName, formData)
            .then((res) => {

                if (res.data.success) {

                    this.setState({
                        clientText: res.data.name
                    })


                }
            })

    }

    getUserName(userID) {

        const formData = new FormData();
        formData.append('userID', userID);

        Axios
            .post(API.users.getUserName, formData)
            .then((res) => {

                if (res.data.success) {

                    this.setState({
                        userText: res.data.name
                    })


                }
            })

    }

    getVenueName(venueID) {

        const formData = new FormData();
        formData.append('venueID', venueID);

        Axios
            .post(API.locations.getVenueName, formData)
            .then((res) => {

                if (res.data.success) {

                    this.setState({
                        venueText: res.data.name
                    })


                }
            })

    }

    componentDidMount() {


    }


    componentWillReceiveProps(nextProps) {
        if(nextProps.selectedClientID){

            this.getClientState(nextProps.selectedClientID)
            this.getClientName(nextProps.selectedClientID)
        }
        if(nextProps.selectedVenueID){

            this.getVenueState(nextProps.selectedVenueID)
            this.getVenueName(nextProps.selectedVenueID)

        }
        if(nextProps.selectedUserID){

            this.getUserName(nextProps.selectedUserID)
        }
    }


    changeClientState(clid, newState) {
        var msg = "Archive"
        if (newState)
        {
            msg = "UnArchive"
        }
        if (window.confirm(msg+" this client, are you sure?")) {
            const formData = new FormData();
            formData.append('clientID', clid);
            formData.append('isLive', newState);

            Axios
                .post(API.clients.setClientState, formData)
                .then((res) => {

                    if (res.data.success) {

                        this.setState({
                            clientIsLive: newState
                        })


                    }
                })
        }
    }

    getClientState(clid)
    {
        const formData = new FormData();
        formData.append('clientID', clid);

        Axios
            .post(API.clients.getClientState, formData)
            .then((res) => {

                if (res.data.success) {

                    this.setState({
                        clientIsLive: res.data.isLive
                    })


                }
            })
    }



    changeVenueState(venueID, newState) {
        var msg = "Archive"
        if (newState)
        {
            msg = "UnArchive"
        }
        if (window.confirm(msg+" this venue, are you sure?")) {
            const formData = new FormData();
            formData.append('venueID', venueID);
            formData.append('isLive', newState);

            Axios
                .post(API.locations.setLocState, formData)
                .then((res) => {

                    if (res.data.success) {

                        this.setState({
                            venueIsLive: newState
                        })


                    }
                })
        }
    }

    getVenueState(venueID)
    {
        const formData = new FormData();
        formData.append('venueID', venueID);

        Axios
            .post(API.locations.getLocState, formData)
            .then((res) => {

                if (res.data.success) {

                    this.setState({
                        venueIsLive: res.data.isLive
                    })


                }
            })
    }



    render() {

        return(
            <div className="adminheader">
                <div className="search">



                    {this.props.selectedUserID  ?

                        this.props.buttonMessage === BUTTON_MSG.EDIT_USER ?
                            <div>
                                <div className="av-button" onClick={() => this.props.buttonMessageHandler(BUTTON_MSG.SAVE_USER)}>Save</div>
                                <div className="av-button" onClick={() => this.props.buttonMessageHandler(undefined)}>Cancel</div>
                            </div>
                            :
                            <div>
                                {this.props.user.admin == "1" &&
                                    <div>
                                        <div className="av-button"
                                             onClick={() => this.props.buttonMessageHandler(BUTTON_MSG.EDIT_USER)}>Edit
                                        </div>
                                        <div className="av-button"
                                             onClick={() => this.props.buttonMessageHandler(BUTTON_MSG.DELETE_USER)}>Delete
                                        </div>
                                    </div>
                                }

                            </div>
                        :
                        this.props.selectedVenueID  ?

                                this.props.buttonMessage === BUTTON_MSG.EDIT_VENUE ?
                                    <div>
                                        <div className="av-button" onClick={() => this.props.buttonMessageHandler(BUTTON_MSG.SAVE_VENUE)}>Save</div>
                                        <div className="av-button" onClick={() => this.props.buttonMessageHandler(undefined)}>Cancel</div>
                                    </div>
                                    :
                                    <div>
                                        <div className="av-button" onClick={() => this.props.buttonMessageHandler(BUTTON_MSG.EDIT_VENUE)}>Edit</div>
                                        {(this.state.venueIsLive !== undefined && this.props.user.admin === "1") &&
                                        <div className="av-button"
                                             onClick={() => this.changeVenueState(this.props.selectedVenueID,!this.state.venueIsLive)}>{this.state.venueIsLive ? "Archive" : "UnArchive"}</div>
                                        }
                                    </div>
                                :
                                <div>
                                    {this.props.selectedClientID  ?

                                        this.props.buttonMessage === BUTTON_MSG.EDIT_CLIENT ?
                                            <div>
                                                <div className="av-button" onClick={() => this.props.buttonMessageHandler(BUTTON_MSG.SAVE_CLIENT)}>Save</div>
                                                <div className="av-button" onClick={() => this.props.buttonMessageHandler(undefined)}>Cancel</div>
                                            </div>
                                            :
                                            <div>
                                                <div className="av-button" onClick={() => this.props.buttonMessageHandler(BUTTON_MSG.EDIT_CLIENT)}>Edit</div>
                                                {(this.state.clientIsLive !== undefined && this.props.user.admin === "1") &&
                                                <div className="av-button"
                                                     onClick={() => this.changeClientState(this.props.selectedClientID,!this.state.clientIsLive)}>{this.state.clientIsLive ? "Archive" : "UnArchive"}</div>
                                                }
                                            </div>
                                        :
                                        <div> </div>
                                    }
                                </div>

                    }





                </div>
                <div className="title">{this.props.screen}</div>
                {this.props.screen === ADMIN_SECTIONS.HOME ?

                    <div className="bread">Admin Dashboard</div>

                    :

                    <div className="bread">
                        <span className="crumb" onClick={() => this.props.itemClickHandler(ADMIN_SECTIONS.HOME)}>Admin Dashboard</span> >
                        {(this.props.selectedUserID || this.props.feedbackItemID || this.props.approvalItemID || this.props.selectedClientID ) ?
                            <span><span className="crumb"  onClick={() => this.props.itemClickHandler(this.props.screen)}>{this.props.screen}</span> >

                                {(this.props.selectedVenueID && this.props.selectedUserID && this.props.selectedClientID) && <span><span className="crumb"  onClick={() => this.props.clientDetailsClickHandler(this.props.screen)}> {this.state.clientText} ></span><span className="crumb"  onClick={() => this.props.venueDetailsClickHandler(this.props.screen)}> {this.state.venueText} ></span></span> }
                                {(this.props.selectedUserID && this.props.selectedClientID && !this.props.selectedVenueID) ? <span><span className="crumb"  onClick={() => this.props.clientDetailsClickHandler(this.props.screen)}> {this.state.clientText} ></span><span> {this.state.userText}</span></span> : (this.props.selectedUserID) && <span>{this.state.userText}</span>}
                                {this.props.feedbackItemID && <span>Feedback Details</span>}
                                {this.props.approvalItemID && <span>Visit Details</span>}
                                {(this.props.selectedVenueID && this.props.selectedClientID && !this.props.selectedUserID) ? <span>
                                    <span className="crumb"  onClick={() => this.props.didSelectVenue(undefined)}>{this.state.clientText} > </span>
                                    <span>{this.state.venueText} </span></span>
                                    :
                                    <span>{(this.props.selectedClientID && !this.props.selectedUserID) && <span>{this.state.clientText}</span>}</span>}


                        </span>
                            :
                            <span> {this.props.screen}</span>
                        }
                    </div>

                }



            </div>

        );
    }




}
export default AdminHeader;
