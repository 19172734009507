import React from 'react'
import Axios from "axios";
import {API} from "../../api/APIClient";
import LocEmailSetting from './LocEmailSetting'

class NotificationSettings extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            submitting: false,
            locations: [],
            allLocs: [],
            clients: [],
            client: -1,


        };
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(event) {

        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        }, this.getLocsList());

    }

    recalcLocs(value) {

        var locations = []
        this.state.allLocs.map((loc, c) => {
            var sectionHeader = undefined
            if (loc.clientname === value){
                locations.push(loc)
            }
            return <LocEmailSetting key={loc.id} locID={loc.id} userID={this.props.user.id} ticked={loc.checked}
                                    name={loc.locationname} client={loc.clientname} header={ sectionHeader } />;
        })
        return locations
    }

    componentDidMount() {

        this.getLocsList();

    }


    getLocsList()
    {
        const formData = new FormData();
        Axios
            .post(API.locations.locationsForUser, formData)
            .then((res) => {

                if (res.data.success) {

                    var title = ""
                    var clients = []
                    res.data.data.map((loc, c) => {
                        var sectionHeader = undefined
                        if (loc.clientname !== title){
                            clients.push(loc.clientname)
                            sectionHeader = loc.clientname
                            title = loc.clientname
                        }
                        return <LocEmailSetting key={loc.id} locID={loc.id} userID={this.props.user.id} ticked={loc.checked}
                                                name={loc.locationname} client={loc.clientname} header={ sectionHeader } />;
                    })
                    var locations = this.recalcLocs(this.state.client)
                    this.setState({
                        allLocs: res.data.data,
                        clients: clients,
                        locations: locations
                    })
                }
            })
    }



    componentWillUnmount() {
        clearTimeout(this._timer);
    }


    addOrRemoveAllClicked(setting){
        var newlocs = [];
        for (var c=0;c < this.state.locations.length; c++) {
            var loc = JSON.parse(JSON.stringify(this.state.locations[c]));
            if (setting === 0) {
                loc.checked = false
            } else {
                loc.checked = true
            }
            newlocs.push(loc)
            const formData = new FormData();
            formData.append('setting', setting);
            formData.append('locationID', loc.id);

            Axios
                .post(API.locations.setEmailSetting, formData)
                .then((res) => {

                    if (res.data.success) {
                      //  this.getLocsList()
                    }

                }).catch(error => {

            });
        }

        this.setState({locations:newlocs})
    }

    render() {

        return(
            <div>
                <div className="cell small-12">
                    <div className="">
                        <div className="title">Email Notifications</div>
                        <div className="small-12 cell"></div>
                        <select className="edit-field" value={this.state.client} name="client" onChange={this.handleChange}>
                            <option value="-1">Select a client</option>

                        {this.state.clients.map((client, c) => {

                            return <option value={client}>{client}</option>
                        })}
                        </select>
                        {this.state.client !== -1
                        && <div>
                            <div className="av-button" onClick={()=>this.addOrRemoveAllClicked(1)}>Check All Locations</div>

                            <div className="av-button" onClick={()=>this.addOrRemoveAllClicked(0)}>Uncheck All Locations</div>
                        </div>
                        }  {this.state.client !== -1
                    && <div>
                    </div>
                    }
                        {this.state.locations.map((loc, c) => {
                            return <LocEmailSetting key={loc.id} locID={loc.id} userID={this.props.user.id} ticked={loc.checked}
                                                    name={loc.locationname} client={loc.clientname} header={ undefined } />;
                        })}

                        </div>
                </div>
            </div>
        );
    }


}
export default NotificationSettings;
